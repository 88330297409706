import React, { useState, useEffect } from "react"
import { useSelector } from 'react-redux'
import axios from "axios"

import {BACKEND_ADDRESS} from "./utils/getEndpoint.js"
import {isValidUrl} from "./utils/helper.js"
import ToastComponent from "./utils/ToastComponent";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Button from '@mui/material/Button';
import {Grid, Typography} from '@mui/material';

import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';

const initializeObjections = (competitors) => {
  var c1 = "competitor", c2 = "competitor", c3 = "competitor"
  if(typeof(competitors) == typeof([]) && competitors.length > 1){
    c1 = competitors[Math.floor(Math.random() * competitors.length)]
    c2 = competitors[Math.floor(Math.random() * competitors.length)]
    c3 = competitors[Math.floor(Math.random() * competitors.length)]
  }
  const obj = {
    'Need & Urgency': [
        'I really think there are other priorities we need to address first', 
        'We\'d like to wait a couple of quarters before we can try this'
    ],
    'Trust': [
        'I do not have confidence that your offering will be useful'
    ],
    'Product benefit & ROI': [],
    'Competition & Status Quo': [
        'We tried something similar earlier, and it did not work out at all',
        'I think ' + c1 + ' is much better',
        'We\'re already doing this using ' + c2 + ', your offering will not add much'
    ],
    'Pricing & Budget': [
        'I think your offering is very expensive',
        'I have also seen ' + c3 + ', and that costs a fraction compared to you'
    ],
    'Purchase Authority': [],
    'Security & Compliance': []
  }
  return obj
}

export default function Onboarding(props){
  const email = useSelector((state) => state.email.value)
  const account = useSelector((state) => state.account.value);

  const [companyName, setCompanyName] = useState("")
  const [companyWebsite, setCompanyWebsite] = useState("")
  //const [productName, setProductName] = useState("")
  const [companyNameError, setCompanyNameError] = useState(false)
  const [companyWebsiteError, setCompanyWebsiteError] = useState(false)
  const [competitorError, setCompetitorError] = useState(false)
  const [competitors, setCompetitors] = useState([])

  //const inputLabelProps = {sx: {color: "#604ca5"}}
  const inputLabelProps = {}
  const styles = {width: "400px"}

  useEffect(() => {
    // check for existing company name and website in DB
    axios.get(BACKEND_ADDRESS + '/account/', {params: {account_id: account}})
    .then((response) => {
      var data = response.data
      if(data["company_name"] !== "" && data["company_website"] !== "" && data["competitors"].length > 0){
        // this means some other user from this account has already set these values up.
        // So we can skip these steps in onboarding for this user

        //close onboarding window
        props.setOnboarded(true)

        //change onboarding state in DB
        axios.post(BACKEND_ADDRESS + '/user_by_email/', {'email': email })
        .then((response) => {
          var data = response.data[0]
          data["onboarding"] = true

          //update in backend
          axios.put(BACKEND_ADDRESS + '/user/', data)
        })
      }else{
        console.log("account onboarding not done yet!")
      }
    })
  }, [])

  const completeOnboarding = () => {
    var validationFlag = true

    // data validation
    if(companyName === ""){
      setCompanyNameError(true)
      validationFlag = false
    }else setCompanyNameError(false)

    if(!isValidUrl(companyWebsite)){
      setCompanyWebsiteError(true)
      validationFlag = false
    }else setCompanyWebsiteError(false)

    if(competitors === null || competitors.length < 2){
      setCompetitorError(true)
      validationFlag = false
    }else{
      setCompetitorError(false)
    }

    //error in data. so return
    if(!validationFlag) return

    //close onboarding window
    props.setOnboarded(true)

    // update company name and website in DB
    axios.get(BACKEND_ADDRESS + '/account/', {params: {account_id: account}})
    .then((response) => {
      var updatedData = response.data
      updatedData["company_name"] = companyName
      updatedData["company_website"] = companyWebsite
      updatedData["competitors"] = competitors
      updatedData["objections"] = initializeObjections(competitors) 

      // update back
      axios.put(BACKEND_ADDRESS + '/account/', updatedData)
    })

    //change onboarding state in DB
    axios.post(BACKEND_ADDRESS + '/user_by_email/', {'email': email })
    .then((response) => {
      var data = response.data[0]
      data["onboarding"] = true

      //update in backend
      axios.put(BACKEND_ADDRESS + '/user/', data)
    })

    ToastComponent("info", "Perfect! You can edit this data later in the Settings section!", "#604ca5")
  }

  return (
    <Dialog open={true}>
      <DialogTitle color="primary">
        Tell us a few more things to get you started!
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container direction="column" spacing={1} alignItems="center">
            <Grid item>
              <TextField required label="Name of your company" placeholder="Microsoft"
              error={companyNameError} helperText={companyNameError?"Company name can't be blank":""}
              variant="standard" sx={styles} InputLabelProps={inputLabelProps} onChange={(e) => setCompanyName(e.target.value)} />
             </Grid>
            <Grid item>
              <TextField required label="Company/Product Website" placeholder="https://www.microsoft.com/"
              error={companyWebsiteError} helperText={companyWebsiteError?"Invalid URL":""}
              variant="standard" sx={styles} InputLabelProps={inputLabelProps} onChange={(e) => setCompanyWebsite(e.target.value)}/>
            </Grid>
            <Grid item>
              <Autocomplete multiple freeSolo options={[]} value={competitors}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                  ))
                }
                sx={styles}
                onChange={(event, newValue) => {setCompetitors(newValue)}}
                renderInput={(params) => (
                  <TextField {...params} required variant="standard" label="Competitor Companies" 
                  placeholder="Press enter after typing to add" helperText={competitorError?"Add at least 2 companies":""} error={competitorError}/>
                )}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={completeOnboarding} autoFocus sx={{textTransform: 'none'}}><PlayCircleOutlineIcon />&nbsp;Let's Start</Button>
      </DialogActions>
    </Dialog>
  )
}