import React, {useState, useEffect} from "react"
import {Typography, Grid} from '@mui/material';
import Paper from "@mui/material/Paper";
//import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
//import { makeStyles, withStyles} from "@mui/material/styles";
import Chip from '@mui/material/Chip';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux'

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";

import axios from "axios"
import { BACKEND_ADDRESS } from "./../utils/getEndpoint";
import {getRoleplayVerdict} from "./../utils/helper"
import {OVERALL_EVALUATION} from "./../utils/constants"

import Search from "./Search"

export const COLORS = {
  primary: "#604CA5",
  yellow: "#ffcf33",
  white: "#ffffff",
  grey: "#cfd8dc",
  secondary: "#f44336",
};

export default function Profile(props) {
  // const classes = useStyles();
  const history = useHistory();
  const email = useSelector((state) => state.email.value);
  //const role = useSelector((state) => state.role.value);

  const [rowsPerPage, setRowsPerPage] = useState(6);
  //const [rowId, setrowId] = useState(null);
  const [page, setPage] = useState(0);
  const [pageData, setpageData] = useState([])
  const [filteredPageData, setFilteredPageData] = useState([])

  useEffect(() => {
    props.callback("heading", "Profile")
    props.callback("description", "Check out your previous roleplay attempts here")
    props.callback("bg", "#fbfafe")
    window.scrollTo(0,0)

    axios.get(BACKEND_ADDRESS + '/plays/all_attempts/', {params: {email: email}})
    .then(response => {
      var data = response.data
      //adding Date
      for(var i=0;i<data.length;i+=1){
        const date = new Date(parseInt(data[i]["_id"]["$oid"].substring(0, 8), 16) * 1000)
        data[i].time = date.toLocaleTimeString([], {hour: "2-digit", minute: "2-digit"}) + " " +
        date.toDateString().slice(4, 11) + "'" +
        date.toDateString().slice(13, 15)

        data[i].rating = getRoleplayVerdict(data[i].evaluation)
      }

      setpageData(data)
      setFilteredPageData(data)
    }).catch(e => console.log(e))
  }, [])

  const searchParams = ["name", "play_type", "call_stage"]

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const handleclickviewbutton = (row) => {
    //setrowId(row);
    const rowId1 = row["$oid"]
    history.push(`/attempt/${rowId1}`)
  }

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
    <Grid item xs={12} lg={9}>
    <center>
    <Paper style={{ borderRadius: "8px", border: "1px", position: "relative", width: "100%"}} elevation={3}>
      <TableContainer component={Paper} align="center" justify="center">
        {/*
        <Typography variant="h6" style={{ padding: 10 }}>
          {" "}
          Activity
        </Typography>
        */
        }
        <Search data={pageData} setData={setFilteredPageData} params={searchParams}/>
        <Table size="small" aria-label="simple table">
          <TableHead style={{ backgroundColor: COLORS.primary }}>
            <TableRow>
              <TableCell align="center" style={{ width: 80, color: COLORS.white }}>
                Time
              </TableCell>
              <TableCell align="center" style={{ width: 150, color: COLORS.white }} >
                Roleplay&nbsp;
              </TableCell>
              <TableCell align="center" style={{ width: 40, color: COLORS.white }}>
                Evaluation&nbsp;
              </TableCell>
              <TableCell align="center" style={{ width: 30, color: COLORS.white }}>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody justify="center">
            {filteredPageData.length !== 0 ? (
              filteredPageData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                .map((row, index) => (
                  <TableRow key={index} style={{height: 50}}>
                    <TableCell style={{ width: 30, padding: 0, margin: 0 }}
                      component="th" scope="row" align="center">
                      {row.time}
                    </TableCell>
                    <TableCell style={{ width: 110, padding: 0, padding: "8px 0px"}} align="center">
                      <Typography variant="body1">{row.name}</Typography>
                      <div>
                        <Chip label={row.play_type} variant="outlined" style={{color: "#604ca5"}} size="small"/>
                        &nbsp;
                        <Chip label={row.call_stage} variant="outlined" style={{color: "#604ca5"}} size="small"/>
                      </div>
                    </TableCell>
                    <TableCell style={{ width: 30, padding: 0, margin: 0 }} align="center">
                      {OVERALL_EVALUATION[row.rating]["category"]}
                    </TableCell>
                    <TableCell style={{ width: 60, padding: 0, margin: 0 }} align="center">
                      <Button value={row._id} color="primary" style={{textTransform: 'none'}} onClick={() => handleclickviewbutton(row._id)}>
                        <u>View</u>
                      </Button>{" "}
                    </TableCell>
                  </TableRow>
                ))
                ) : (
              <></>
            )}

          { filteredPageData && filteredPageData.length !== 0 ?
          ((1 + page) * rowsPerPage - filteredPageData.length) > 0 && (
                <TableRow style={{ height: 50 * ((1 + page) * rowsPerPage - filteredPageData.length) }}>
                  <TableCell />
                </TableRow>
              )  :
              <TableRow style={{ height: 50 * 5 }}>
                <TableCell colSpan={1}/>
                <TableCell colSpan={2}>
                  <center><i><Typography>Nothing to show here</Typography></i></center>
                </TableCell>
                <TableCell colSpan={1}/>
              </TableRow>
}
          </TableBody>
          {filteredPageData ? (
            <TableFooter>
              <TableRow>
                <TablePagination rowsPerPageOptions={[8]} labelRowsPerPage="Rows"
                  count={filteredPageData.length} rowsPerPage={rowsPerPage} page={page}
                  onPageChange={handleChangePage}
                />
              </TableRow>
            </TableFooter>
          ) : (
            <></>
          )}
        </Table>
      </TableContainer>
    </Paper>
    </center>
    </Grid>
    </Grid>
  )
}
