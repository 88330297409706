import React from 'react';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
//import na_male_1 from "./../../prospect/na_male_1.png"

const ContinuousRippleEffect = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  borderRadius: '50%',
  background: 'rgba(68, 183, 0, 1.0)',
  zIndex: 0,
  animation: 'rippleAnimation 2s infinite ',
  '@keyframes rippleAnimation': {
    '0%': {
      transform: 'scale(1)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(3)',
      opacity: 0,
    },
  }
});

export default function RippleAvatar(props){
  const rippleSize = props.size
  const avatarSize = rippleSize - 12

  const RippleContainer = styled('div')({
    position: 'relative',
    width: rippleSize,
    height: rippleSize,
    borderRadius: '50%',
    overflow: 'hidden',
    left: "50%",
    transform: "translateX(-50%)"
  });

  return (
    <RippleContainer>
      <Avatar alt="User Name" src={props.photo} style={{ width: avatarSize, height: avatarSize,
        marginLeft: "6px", marginTop: "6px", position: 'relative', zIndex: 1 }} />
      {props.status?<ContinuousRippleEffect />:<></>}
    </RippleContainer>
  );
};
