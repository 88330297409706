import na_male_1 from "./../prospect/na_male_1.png"
import na_female_1 from "./../prospect/na_female_1.png"
import europe_male_1 from "./../prospect/europe_male_1.png"
import europe_female_1 from "./../prospect/europe_female_1.png"

const prospects = {
  "654a44c397e3ad87c3fce61a": {photo: na_male_1, name: "Mathew", gender: "Male", voice_id: "pNInz6obpgDQGcFmaJgB",
    description: "Mathew is a social buyer with a high level of agreeableness, making his interactions largely rapport driven. While he can be talkative at times, his questioning style is friendly and he demonstrates a high level of knowledge and interest in the conversations.",
    tags: {buying_style: "Social Buyer", relationship: "Rapport Driven", talking_style: "Friendly", brevity: "Talkative"}},
  "654a450497e3ad87c3fce61b": {photo: na_female_1, name: "Mary", gender: "Female", voice_id: "4ZWg0taZmRsBgQRd6ykn",
    description: "As a feature-driven buyer, Mary is succinct and precise in her conversations, often preferring a transactional relationship over establishing rapport. Her assertive questioning style is complemented by a medium level of agreeableness and knowledge in the discussion.",
    tags: {buying_style: "Feature Driven", relationship: "Transactional", talking_style: "Assertive", brevity: "Concise"}},
  "654a458797e3ad87c3fce61c": {photo: europe_male_1, name: "Anthony", gender: "Male", voice_id: "v6FbZdPpBrF531vQgygw",
    description: "Anthony, the economical buyer, engages in a neutral level of conversation but is quite low on agreeableness, preferring transactional exchanges. Despite his confrontational questioning style, he exhibits a high level of knowledge and interest in the conversation.",
    tags: {buying_style: "ROI Driven", relationship: "Transactional", talking_style: "Confrontational", brevity: "Concise"}},
  "654a45fb97e3ad87c3fce61e": {photo: europe_female_1, name: "Lisa", gender: "Female", voice_id: "75QGxHd9bxQ1N8d80Pm0",
    description: "ROI Driven. She often changes her tone from agreeable & rapport driven to inflexible & transactional (or vice versa). Her conversations tend to be concise. She adopts an assertive questioning style and demonstrates a low level of knowledge and interest in the conversation.",
    tags: {buying_style: "ROI Driven", relationship: "Transactional", talking_style: "Assertive", brevity: "Talkative"}}
}

export default prospects;
