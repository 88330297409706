import React, {useState, useEffect} from "react"
import { Typography} from '@mui/material';
import Paper from "@mui/material/Paper";
//import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
//import { makeStyles, withStyles} from "@mui/material/styles";
//import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux'
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import axios from "axios"
import { BACKEND_ADDRESS } from "./../utils/getEndpoint";
import ToastComponent from "./../utils/ToastComponent";
//import emailjs from 'emailjs-com';
import fire from "./../utils/base";

export const COLORS = {
  primary: "#604CA5",
  yellow: "#ffcf33",
  white: "#ffffff",
  grey: "#cfd8dc",
  secondary: "#f44336",
};

export default function Team(props) {
  //const history = useHistory();
  const email = useSelector((state) => state.email.value);
  //const role = useSelector((state) => state.role.value);
  const account = useSelector((state) => state.account.value);
  const [name, setName] = useState("")
  const [rowsPerPage, setRowsPerPage] = useState(8);
  //const [rowId, setrowId] = useState(null);
  const [page, setPage] = useState(0);
  const [users, setUsers] = useState([]);
  const [accountData, setAccountData] = useState({})
  const [inviteEmail, setInviteEmail] = useState("")
  const [invitePopup, setInvitePopup] = useState(false)
  const [deletePopup, setDeletePopup] = useState(-1) // index of the user to delete. 0 indexing

  useEffect(() => {
    props.callback("heading", "Team")
    props.callback("description", "Manage your team members here")
    props.callback("bg", "#fbfafe")
    window.scrollTo(0,0) //scrolling to top of new screen

    axios.get(BACKEND_ADDRESS + '/account/', {params: {account_id: account}})
    .then((response) => {
      var tt = response.data
      setUsers(tt["users"])
      setAccountData(tt)
    }).catch(error => console.error(error));
  }, [])

  //set name
  fire.auth().onAuthStateChanged((user)=>{
    if((user!==null && name !== user.displayName && user.displayName != null) ){
      setName(user.displayName);
    }
  })

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deleteUser = (ind, isActive) => {
    if(isActive){
      //make user inactive in backend
      axios.delete(BACKEND_ADDRESS + '/user/', {params: {email: users[ind]["email"], account_id: account}})
      .catch(error => console.error(error));

      /*
      *******
      TEMPORARY WORKAROUND:
      User is not deleted from firebase and new access needs manual intervention
      by Aspiro on signup page because firebase admin sdk is needed for deleting
      a different user. See more here-
      https://firebase.google.com/docs/auth/admin/manage-users#delete_a_user
      ******
      // delete user from firebase
      // fire.auth().getUserByEmail(users[ind]["email"])
      // .then((userRecord) => {
      //   // User found
      //   const uid = userRecord.uid;
      //   fire.auth().deleteUser(uid);
      // })
      */
    }else{
      //delete user completely from backend
      axios.delete(BACKEND_ADDRESS + '/delete_user/', {params: {email: users[ind]["email"], account_id: account}})
      .catch(error => console.error(error));
    }

    //update users and accountdata state variables
    var userCopy = JSON.parse(JSON.stringify(users)) //deepcopy
    userCopy.splice(ind, 1)
    setUsers(userCopy)

    var accountDataCopy = JSON.parse(JSON.stringify(accountData)) //deepcopy
    accountDataCopy["users"] = userCopy
    setAccountData(accountDataCopy)

    //update account data in backend
    axios.put(BACKEND_ADDRESS + '/account/', accountDataCopy)
    .catch(error => console.error(error));

    ToastComponent("success", "User/User Invite has been deleted!", "#604ca5")
    // close the popup
    setDeletePopup(-1)
  }

  const inviteUser = (e) => {
    for(var i=0;i<users.length;i+=1){
      if(users[i].email === inviteEmail){
        if(users[i].status === "pending"){
          ToastComponent("error", "User is already invited!")
          return
        }else{
          ToastComponent("error", "User already registered in this team!")
          return
        }
      }
    }

    if (inviteEmail !== "") {
      const params = {
        sender_email: "shalabh@letsaspiro.com",
        recipient_email: inviteEmail,
        from_name: name,
        from_email: email,
        invite_link: window.location.protocol + "//" + window.location.host + "/signup?account=" + account,
        account: account
      }

      axios.post(BACKEND_ADDRESS + '/invite_user/', params)
      .then(response => {
        if(response.data["Message"].substring(0, 11) === "Email sent!"){
          console.log('user invited!', response.status, response.text);
          ToastComponent("success", "Invite Sent!", "#604ca5")

          var userCopy = JSON.parse(JSON.stringify(users)) //deepcopy
          userCopy.push({email: inviteEmail, status: "pending"})
          setUsers(userCopy)

          var accountDataCopy = JSON.parse(JSON.stringify(accountData)) //deepcopy
          accountDataCopy["users"] = userCopy
          setAccountData(accountDataCopy)

          //update invited user in backend
          axios.put(BACKEND_ADDRESS + '/account/', accountDataCopy)
          .catch(error => console.error(error));

          if(response.data["state"] === 1){
            // fresh user
            //create new inactive user. They will become active after they accept the invite
            const userData = {
              name: "", //to be updated later when user is created
              email: inviteEmail,
              role: "User",
              reports: inviteEmail,
              onboarding: false,
              is_active: false,
              account_id: account
            }
            axios.post(BACKEND_ADDRESS + '/user/', userData)
            .catch(error => console.error(error));
          }
        }else{
          if(response.data["state"] === 2){
            ToastComponent("error", "User already registered on Aspiro!")
          }else if(response.data["state"] === 4){
            ToastComponent("error", "User is already invited!")
          }else{
            ToastComponent("error", "Can't invite user! Please check the email ID")
          }
        }
      }).catch(error => {
        console.error(error)
        ToastComponent("error", "Can't invite user! Please check the email ID")
      });

      //close popup
      setInvitePopup(false)
    }else{
      ToastComponent("error", "Email ID field can't be blank")
    }
  }

  const cancelInvite = (index) => {
    // it's effectively the same
    deleteUser(index, false)
  }

  return (
    <center>
    <Paper style={{ borderRadius: "8px", border: "1px", position: "relative", height: "50%", width: "70%"}} elevation={3}>
      <TableContainer component={Paper} align="center" justify="center">
        <Table style={{alignItems: "center",alignContent: "center", justifyContent: "center"}}
        size="small" aria-label="simple table">
          <TableHead style={{ backgroundColor: COLORS.primary }}>
            <TableRow>
              <TableCell align="center" style={{ width: 80, color: COLORS.white }}>
                Name
              </TableCell>
              <TableCell align="center" style={{ width: 150, color: COLORS.white }} >
                Email ID&nbsp;
              </TableCell>
              <TableCell align="center" style={{ width: 40, color: COLORS.white }}>
                Status&nbsp;
              </TableCell>
              <TableCell align="center" style={{ width: 30, color: COLORS.white }}>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody justify="center">
            {users.length !== 0 ? (
              users.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
                .map((row, index) => (
                  <TableRow key={index} style={{height: 50}}>
                    <TableCell style={{ width: 30, padding: 0, margin: 0 }}
                      component="th" scope="row" align="center">
                      {row.name}
                    </TableCell>
                    <TableCell style={{ width: 110, padding: 0, margin: 0 }} align="center">
                      {row.email}
                    </TableCell>
                    <TableCell style={{ width: 30, padding: 0, margin: 0 }} align="center">
                      {row.status === "pending"?<i>Invited</i>:<></>}
                    </TableCell>
                    <TableCell style={{ width: 60, padding: 0, margin: 0 }} align="center">
                      {row.status === "pending"?
                      <Button variant="text" color="primary" style={{textTransform: "none"}} onClick={() => cancelInvite(index)}>
                        Cancel Invite
                      </Button>
                      :
                      <IconButton value={row._id} color="primary" onClick={() => setDeletePopup(index)}>
                        {email === row.email?<></>:<DeleteIcon/>}
                      </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                ))
                ) : (
              <></>
            )}

          <Dialog open={deletePopup<0?false:true} onClose={() => setDeletePopup(-1)}>
            <DialogTitle>
              Delete User?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {
                  deletePopup < 0? <></>:
                  <Typography>Are you sure you want to delete <b>{users[deletePopup]["email"]}</b> from Aspiro?</Typography>
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => deleteUser(deletePopup, true)} autoFocus>Delete User</Button>
            </DialogActions>
          </Dialog>

          { users && users.length !== 0 ?
          ((1 + page) * rowsPerPage - users.length) > 0 && (
                <TableRow style={{ height: 50 * ((1 + page) * rowsPerPage - users.length) }}>
                  <TableCell />
                </TableRow>
              )  :
              <TableRow style={{ height: 50 * 5 }}>
                <TableCell colSpan={1}/>
                <TableCell colSpan={2}>
                  <center><i><Typography>Nothing to show here</Typography></i></center>
                </TableCell>
                <TableCell colSpan={1}/>
              </TableRow>
          }
          </TableBody>

          {users ? (
            <TableFooter>
              <TableRow>
                <TableCell>
                  <Button variant="contained" color="primary" style={{textTransform: "none"}} onClick={() => setInvitePopup(true)}>
                    <AddIcon /> Invite Users
                  </Button>
                </TableCell>
                <TablePagination rowsPerPageOptions={[8]} labelRowsPerPage="Rows"
                count={users.length}
                  rowsPerPage={rowsPerPage} page={page}
                  onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
              </TableRow>
            </TableFooter>
          ) : (
            <></>
          )}

          <Dialog open={invitePopup} onClose={() => setInvitePopup(false)}>
            <DialogTitle>
              Invite User
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <TextField required label="Email" variant="standard" sx={{width: "300px"}}
                 InputLabelProps={{sx: {color: "#604ca5"}}} onChange={e => setInviteEmail(e.target.value.trim())} />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={inviteUser} style={{textTransform: 'none'}} autoFocus>Invite</Button>
            </DialogActions>
          </Dialog>
        </Table>
      </TableContainer>
    </Paper>
    </center>
  )
}
