// adapted from https://dev.to/mar1anna/create-a-search-bar-with-react-and-material-ui-4he
import { useState, useEffect} from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";

const filterData = (query, data, params) => {
  if (!query) {
    return data;
  } else {
    const initialValue = false;

    return data.filter((d) => params.reduce(
      (accumulator, currentValue) => accumulator || d[currentValue].toLowerCase().includes(query),
      initialValue,
    ));
  }
};

const SearchBar = ({setSearchQuery}) => (
  <form>
    <TextField
      id="search-bar"
      className="text"
      onInput={(e) => {setSearchQuery(e.target.value)}}
      variant="outlined"
      placeholder="Search..."
      size="small"
      sx={{width:"92%"}}
    />
    <IconButton aria-label="search">
      <SearchIcon style={{ fill: "#604ca5" }} />
    </IconButton>

  </form>
);

export default function Search(props) {
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const dataFiltered = filterData(searchQuery, props.data, props.params);
    props.setData(dataFiltered)
  }, [searchQuery])


  return (
    <div
      style={{
        display: "flex",
        justifyContent: "left",
        flexDirection: "column",
        padding: "12px 12px 0px 12px"
      }}
    >
      <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
      {/*
      <div style={{ padding: 3 }}>
        {dataFiltered.map((d) => (
          <div
            className="text"
            style={{
              padding: 5,
              justifyContent: "normal",
              fontSize: 20,
              color: "#604ca5",
              margin: 1,
              width: "250px",
              BorderColor: "green",
              borderWidth: "10px"
            }}
            key={d.id}
          >
            {d}
          </div>
        ))}
      </div>
      */}
    </div>
  );
}
