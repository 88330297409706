import React, { useState } from 'react';
import {
  TextField,
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  Stack,
  Grid,
  Typography
} from '@mui/material';
import { Clear, Add } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const Objections = (props) => {
  // Initial list data with multiple lists with descriptions
  const [listData, setListData] = useState(props.objections);

  // Handle adding a new entry to a specific list
  const handleAddEntry = (listKey) => {
    const newVal = {
      ...listData,
      [listKey]: [...listData[listKey], '']
    }
    setListData(newVal)
    props.changeCallback(newVal)
  };

  // Handle deleting an entry from a specific list
  const handleDeleteEntry = (listKey, index) => {
    const newVal = {
      ...listData,
      [listKey]: listData[listKey].filter((_, i) => i !== index)
    }
    setListData(newVal)
    props.changeCallback(newVal)
  };

  // Handle changing an entry in a specific list
  const handleChange = (listKey, index, value) => {
    const newVal = { ...listData };
    newVal[listKey][index] = value;

    setListData(newVal)
    props.changeCallback(newVal)
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h6" color="primary" gutterBottom>Roleplay Objections</Typography>
        <Typography variant="body1">Enter questions that your buyers ask in a typical sales call. This will increase the realism of roleplays for your team.</Typography>
      </Grid>
      {Object.keys(listData).map((listKey) => (
        <Grid item xs={12} key={listKey} sx={{marginLeft: 1}}>
          <Stack direction="row" alignItems="center">
            <Typography variant="body1" color="primary" >
              {listKey}
            </Typography>
            <IconButton onClick={() => handleAddEntry(listKey)} variant="outlined" size="small">
              <Add />
            </IconButton>
          </Stack>
          <List dense>
            {listData[listKey].map((entry, index) => (
              <ListItem key={index}>
                <Grid container spacing={1}>
                  <Grid item xs={11}>
                    <TextField
                      fullWidth size="small" multiline variant="outlined" value={entry}
                      onChange={(e) => handleChange(listKey, index, e.target.value)}
                      style={{ marginBottom: 1 }} placeholder="Add Objection"
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton edge="end" onClick={() => handleDeleteEntry(listKey, index)} size="small">
                      <Clear />
                    </IconButton>
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        </Grid>
      ))}
    </Grid>
  );
};

export default Objections;
