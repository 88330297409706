import React from "react"
import {Typography} from '@mui/material';

export default function Dashboard(props) {
  props.callback("heading", "Dashboard")
  props.callback("description", "")
  props.callback("bg", "#fbfafe")
  window.scrollTo(0,0) //scrolling to top of new screen
  return (
    <Typography>

    </Typography>
  )
}
