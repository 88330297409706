import {useState} from "react"
//import { useHistory } from "react-router-dom";

import Typography from "@mui/material/Typography"
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Paper from "@mui/material/Paper";

export default function MeetingCTABanner(props) {
  var defaultDisplay = true;
  const days = 2 //show it again after 2 days of closing the banner
  const expiry_time = days*24*3600*1000;
  const timestampString = localStorage.getItem('meetingCTATimeStamp');

  if (timestampString) {
    const lastClosedTimestamp = new Date(Number(timestampString)).getTime();
    if(new Date().getTime() - lastClosedTimestamp < expiry_time){
      defaultDisplay = false;
    }
  }

  const [display, setDisplay] = useState(defaultDisplay)
  //const history = useHistory();

  const onClose = () => {
    setDisplay(false)

    //update last closed timestamp
    const timestamp = new Date().getTime(); // Get current time in milliseconds
    //console.log(timestamp)
    localStorage.setItem('meetingCTATimeStamp', timestamp); // Store in localStorage
  }

  return (
    <>
    {display?
      <Paper square style={{width: "100%", position: "fixed", bottom: "0", top: "auto", zIndex: 7, padding: "6px 12px", borderTop: "3px solid #000000", marginLeft: "-10px"}}>
        <Typography display="inline">
          Have questions or want customizations? Please email us at hello@letsaspiro.com or&nbsp;
          <Button variant ="contained" size="small"
          onClick={() => {window.open("https://calendly.com/puneetlamba/aspiro15min", "_blank")}}>
            Book a Demo
          </Button>
          &nbsp;with us now
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon style={{maringTop: "6px"}}/>
        </IconButton>
      </Paper>
      :
      <></>
    }
    </>
  )
}
