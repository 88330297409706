//https://chat.openai.com/share/9b5ea425-841e-4544-905a-690e5cbc017e
const customVocab = [
  "Aspiro",
  "Lisa",
  "Mary",
  "Mathew",
  "Anthony",
  // "SAAS",
  // "ARR",
  // "MRR",
  // "CFO",
  // "CTO",
  // "CXO",
  // "CISO",
  // "CSO",
  // "CMO",
  // "presales",
  // "calendly",
  // "ACV",
  // "freemium",
  // "CAC",
  // "API",
  // "onprem",
  // "CPM",
  // "CMS",
  // "NPS",
  // "MQL",
  // "SQL",
  // "LTV",
  // "upsell",
  // "cross-sell",
  // "GTM",
  // "PMF",
  // "Marketo",
  // "Trello",
  // "Kubernetes",
  // "Docker",
  // "SOC2",
  // "ISO 27001"
]

export default customVocab
