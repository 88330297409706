import { useState } from 'react';

export default function Hover({ children }) {
  const [hover, setHover] = useState();

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <div style={{ display: 'contents' }}
    onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children(hover)}
    </div>
  );
}
