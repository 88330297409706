import React from "react"
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

function Loading(props){
  //sends loading with caption if caption is passed as prop, else passes a random caption
  const sales_quotes = [
    "They said my talking to listening ratio was bad, so I stopped doing both of them.",
    "Large crystal ball for sale. $50, but you will haggle me down to $35.",
    "This product will cut your workload by 50% I said. So, he took two of them.",
    "I was selling a broken quiz machine for $10. No questions asked.",
    "Nobody likes to be sold, but everyone loves to buy.",
    "For sale, incredible Hulk t-shirt. Usual wear and tear.",
    "A salesman minus enthusiasm is just another clerk.",
    "Sell the results, not the nuts and bolts.",
    "The prospect said she was interested. Still following up with her after an year.",
    "They said nothing is impossible. But I have been doing nothing all along."
  ]
  const ind = Math.floor((Math.random() * 10));
  //const italics = props.italics? props.italics: false

  return(
    <div style={{textAlign: "center", paddingTop: "10%", paddingBottom:"10%"}}>
      <Typography variant="h6" fontStyle="italic">{props.caption? props.caption: sales_quotes[ind]}</Typography>
      <br />
      <CircularProgress />
    </div>
  )
}

export default Loading
