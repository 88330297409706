import React, {useState} from 'react';
import {Typography} from '@mui/material';
import { createTheme , ThemeProvider } from '@mui/material/styles';

import Tracks from "./tracks/Tracks.js"
import Profile from "./profile/Profile.js"
import Dashboard from "./dashboard/Dashboard.js"
import Companies from "./companies/Companies.js"
import Team from "./team/Team.js"
import Settings from "./settings/Settings.js"

const headingTheme = createTheme({
    typography: {
      fontFamily: ['Roboto Mono', "monospace"].join(","),
    },
  });

export default function ContentGrid(props) {
  const [heading, setHeading] = useState('');
  const [description, setDescription] = useState('');
  const [bg, setBg] = useState('');

  const content_callback = (key, value) => {
    const keyMap = {
      "heading": setHeading,
      "description": setDescription,
      "bg": setBg
    }

    if (key in keyMap){
      const setFunction = keyMap[key]
      setFunction(value)
    }
    else{
      console.log("Can't set the value of "  + key)
    }
  }

  const component = {
    "tracks": <Tracks key={props.subMode} callback={content_callback} {...props}/> ,
    "profile": <Profile callback={content_callback}/> ,
    "dashboard": <Dashboard callback={content_callback}/> ,
    "companies": <Companies key={props.subMode} callback={content_callback} {...props}/> ,
    "team": <Team callback={content_callback}/> ,
    "settings": <Settings callback={content_callback} />
  }

  const headingHeight = "85px"
  const contentMargin = headingHeight + "46px"

  return (
    <>
      <div style={{height: headingHeight, padding: "6px 24px 6px", backgroundColor: "white"}}>
        {//<ThemeProvider theme={headingTheme}>
        }
          <Typography variant="h3" display="inline" sx={{
            overflow: 'hidden', //all this is added to contrain heading to one line- https://stackoverflow.com/a/71324014/9160820
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical'}} >
            {heading}
          </Typography>
        {//</ThemeProvider>
        }
        <Typography variant="h6">
          {description}
        </Typography>
      </div>
      <div style={{ padding: "12px 30px 24px", position: "absolute", left: "80px", right: "0px"}}>
        {component[props["mode"]]}
      </div>
    </>
  )
}
