import React, {useState, useEffect, useRef} from "react"
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {Grid, Typography} from '@mui/material';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
//import IconButton from "@mui/material/IconButton";
import Chip from '@mui/material/Chip';

import useClasses from './../../utils/useClasses'
import prospects from "./../../utils/prospects"
import axios from "axios";
import Loading from "./../../utils/Loading"

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import TuneIcon from '@mui/icons-material/Tune';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import ReplayIcon from '@mui/icons-material/Replay';
import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { NavLink } from "react-router-dom";
import CachedIcon from '@mui/icons-material/Cached';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Switch from '@mui/material/Switch';

import VocodeWidget from "./../../vocode/VocodeWidget"
import RippleAvatar from "./RippleAvatar"
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';

import MeetingCTA from "./../../utils/MeetingCTA"
import {objectId, getRoleplayVerdict} from "./../../utils/helper"
import {COLD_CALL, SELL_TO_A_PERSONA, FOLLOW_TALKING_POINTS, HANDLE_A_SITUATION, APPLY_A_TACTIC, BACKEND_ADDRESS} from "./../../utils/constants"
import {PLAY_STAGE_HELPER_CONTENT, OVERALL_EVALUATION} from "./../../utils/constants"

const findMaxRoleplayTime = (playContent) => {
  if(playContent['call_stage'] === COLD_CALL){
    if(playContent['play_type'] === SELL_TO_A_PERSONA) return 600
    else return 300
  }else return 300
}

var emojiMapper = {
  [FOLLOW_TALKING_POINTS]: <>📋</>,
  [HANDLE_A_SITUATION]: <>🚧</>,
  [APPLY_A_TACTIC]: <>🪄</>,
  [SELL_TO_A_PERSONA]: <>🧑🏻</>
}

// const getSellerCompany = (allCompanies) => {
//   if(typeof(allCompanies) == 'object'){
//     for(var i=0;i<allCompanies.length;i+=1){
//       if(!allCompanies[i]["is_template"]){
//         return allCompanies[i]["seller_company"]
//       }
//     }
//   }
//   return ""
// }

// Characteristics Options
// buying style- social buyer, ROI driven, feature driven
// relationship- rapport driven, transactional
// talking style- friendly, assertive, Confrontational
// brevity- talkative, concise
const buyingStyleDesign = (buying_style) => {
  return (
    <>
    {buying_style === "Social Buyer"?
      <><Typography variant="h6" display="inline">🍻</Typography><Typography display="inline"> Social Buyer</Typography></>
      :
    buying_style === "ROI Driven"?
      <><Typography variant="h6" display="inline">💰</Typography><Typography display="inline"> ROI Driven</Typography></>
      :
    buying_style === "Feature Driven"?
      <><Typography variant="h6" display="inline">✨️</Typography><Typography display="inline"> Feature Driven</Typography></>
      :
      <></>
    }
    </>
  )
}

const relationshipDesign = (relationship) => {
  return (
    <>
    {relationship === "Rapport Driven"?
      <><Typography variant="h6" display="inline">🤝</Typography><Typography display="inline"> Rapport Driven</Typography></>
      :
    relationship === "Transactional"?
      <><Typography variant="h6" display="inline">💳</Typography><Typography display="inline"> Transactional</Typography></>
      :
      <></>
    }
    </>
  )
}

const talkingStyleDesign = (talking_style) => {
  return (
    <>
    {talking_style === "Friendly"?
      <><Typography variant="h6" display="inline">🤗</Typography><Typography display="inline">️ Friendly</Typography></>
      :
    talking_style === "Assertive"?
      <><Typography variant="h6" display="inline">💪</Typography><Typography display="inline"> Assertive</Typography></>
      :
    talking_style === "Confrontational"?
      <><Typography variant="h6" display="inline">⚔️</Typography><Typography display="inline"> Confrontational</Typography></>
      :
      <></>
    }
    </>
  )
}

const brevityDesign = (brevity) => {
  return (
    <>
    {brevity === "Talkative"?
      <><Typography variant="h6" display="inline">🗣</Typography><Typography display="inline">️ Talkative</Typography></>
      :
    brevity === "Concise"?
      <><Typography variant="h6" display="inline">📌</Typography><Typography display="inline">️ Concise</Typography></>
      :
      <></>
    }
    </>
  )
}

export default function Roleplay(props){
  var data = props.playContent
  const [buyerCompanies, setBuyerCompanies] = useState([])
  const [personas, setPersonas] = useState([])
  const [trigger, setTrigger] = useState(null) //set entire trigger object here
  const [state, setState] = useState(props.initialState) //values- start/roleplay/evaluation
  const [roleplayState, setRoleplayState] = useState(0) //values- current timestamp (roleplay began) or 0
  const [activePersona, setActivePersona] = useState(null)
  const [activeCompany, setActiveCompany] = useState(null)
  const [tempPersona, setTempPersona] = useState(0) // index number of persona selected in customization popup
  const [tempCompany, setTempCompany] = useState(0) // index number of buyerCompany selected in customization popup
  const [useSampleCompany, setUseSampleCompany] = useState(true)
  const [conversation, setConversation] = useState([])
  const [evaluation, setEvaluation] = useState(null)
  //const [transcript, setTranscript] = useState([])
  const [triggerMessage, setTriggerMessage] = useState("") //triggerMessage is uniquely generated dependeding on prospect
  const [triggerDescription, setTriggerDescription] = useState("")//triggerDescription is uniquely generated depending on prospect
  const [applicableObjections, setApplicableObjections] = useState(null)
  const [customProspect, setCustomProspect] = useState(false) // if true, dialog to set customProspect is opened
  const [showCTA, setShowCTA] = useState(false)
  const [showScore, setShowScore] = useState(false)
  const [convId, setConvId] = useState("")
  const [oid, setOid] = useState(objectId())

  const [sellerCompany, setSellerCompany] = useState(null) //null. initial value can't be ""

  //using refs to always access the latest state variable value in callback functions storeConversation and onStopRoleplay
  //https://chatgpt.com/share/fba16aba-6b58-4800-9920-b32b9c2f1f90
  const roleplayStateRef = useRef(roleplayState)
  const conversationRef = useRef(conversation)
  // Update the ref whenever the state changes
  useEffect(() => {roleplayStateRef.current = roleplayState}, [roleplayState])
  useEffect(() => {conversationRef.current = conversation}, [conversation])

  const email = useSelector((state) => state.email.value);
  const account = useSelector((state) => state.account.value);
  let history = useHistory();

  useEffect(() => {
    //console.log(props)
    if(state === "roleplay" || state === "start"){
      //get role and onboarding status and store it in redux
      Promise.all([
        axios.get(BACKEND_ADDRESS + '/companies/get_company_templates/', {}),
        axios.get(BACKEND_ADDRESS + '/companies/get_all_companies/', {'params': {'account_id': account}}),
        axios.get(BACKEND_ADDRESS + '/personas/get_all_personas/', {}),
        axios.get(BACKEND_ADDRESS + '/triggers/get_trigger/', {params: {id: data['trigger']}})
      ]).then((response) => {
        var allCompanies = response[0].data.concat(response[1].data)
        var characters = response[2].data

        // restrain allCompanies to only one buyerCompany if buyer_company is mentioned in playContent
        if(props.playContent["buyer_company"] !== ""){
          for(var i=0;i<allCompanies.length;i+=1){
            if(allCompanies[i]["_id"]["$oid"] === props.playContent["buyer_company"]){
              allCompanies = [allCompanies[i]]
              break
            }
          }
        }
        // restrain personas to only one persona if persona_id is mentioned in playContent
        if(props.playContent["persona_id"] !== ""){
          for(var i=0;i<characters.length;i+=1){
            if(characters[i]["_id"]["$oid"] === props.playContent["persona_id"]){
              characters = [characters[i]]
              break
            }
          }
        }

        //adding photo loc and voice_id attributes
        for(var i=0;i<characters.length;i+=1){
          characters[i]["photo"] = prospects[characters[i]["_id"]["$oid"]]["photo"]
          characters[i]["voice_id"] = prospects[characters[i]["_id"]["$oid"]]["voice_id"]
        }

        setTrigger(response[3].data[0])
        setBuyerCompanies(allCompanies)
        setPersonas(characters)

        generateNewCharacter(characters, allCompanies)
      }).catch(error => console.error(error))

      // check for existing company name and website in DB
      axios.get(BACKEND_ADDRESS + '/account/', {params: {account_id: account}})
      .then((response) => {
        var tt = response.data
        setSellerCompany(tt["company_name"])

        var allKeys = Object.keys(tt["objections"])
        var allObjs = []
        for(var i=0; i<allKeys.length; i+=1){
          allObjs = allObjs.concat(tt["objections"][allKeys[i]])
        }
        setApplicableObjections(allObjs)
      }).catch(error => console.error(error));
    }

    if(state === "evaluation"){
      axios.get(BACKEND_ADDRESS + '/plays/attempt/', {params: {id: props.a_id}})
      .then((response) => {
        //console.log(response.data)
        setEvaluation(response.data[0])
      }).catch(error => console.error(error));
    }
  }, [state]);


  useEffect(() => {
    if(activePersona && activeCompany && sellerCompany !== null){
      const params = {
        id: props.playContent.trigger,
        buyer_name: activePersona.name,
        buyer_company: activeCompany.company,
        seller_company: activeCompany["is_template"]?activeCompany.seller_company:sellerCompany
      }
      axios.get(BACKEND_ADDRESS + '/triggers/initial_message/', {params: params})
      .then((response) => {
        setTriggerMessage(response.data["trigger_message"])
        setTriggerDescription(response.data["trigger_description"])
      }).catch(error => console.error(error));
    }
  }, [activePersona, activeCompany, sellerCompany]);

  const generateNewCharacter = (p, allCompanies) => {
    setTriggerDescription("") //so that it starts loading again on UI
    setTriggerMessage("")

    //set the persona
    if(p.length > 0){
      let x = Math.floor((Math.random() * p.length));
      let activeP = p[x]
      setActivePersona(activeP)
      setTempPersona(x)
    }
    if(allCompanies.length > 0){
      let x = Math.floor((Math.random() * allCompanies.length));
      let activeC = allCompanies[x]
      setActiveCompany(activeC)
      setTempCompany(x)
    }
  }

  const setNewCharacter = () => {
    setCustomProspect(false) //closePopup

    if(personas[tempPersona]["_id"]["$oid"] === activePersona["_id"]["$oid"] &&
    buyerCompanies[tempCompany]["_id"]["$oid"] === activeCompany["_id"]["$oid"]){
      return
    }
    setTriggerDescription("") //so that it starts loading again on UI
    setTriggerMessage("")

    var pe = personas[tempPersona]
    pe["photo"] = prospects[pe["_id"]["$oid"]]["photo"]
    pe["voice_id"] = prospects[pe["_id"]["$oid"]]["voice_id"]

    setActivePersona(pe)
    setActiveCompany(buyerCompanies[tempCompany])
  }

  function onStartRoleplay(){
    setRoleplayState((prev) => (Date.now() / 1000))
  }

  function onStopRoleplay(convId, isConfirmed){
    console.log("running scoring")

    //if(isConfirmed){
      const conv_data = {
        email: email,
        play_id: props.m_id,
        track_id: props.t_id,
        conversation: conversationRef.current,
        buyer_company: JSON.parse(JSON.stringify(activeCompany)), //deepcopy as we will edit it
        persona: JSON.parse(JSON.stringify(activePersona)), //deepcopy as we will edit it
        call_duration: Math.ceil(Date.now()/1000 - roleplayStateRef.current)
      }

      if("_id" in conv_data.buyer_company && "_id" in conv_data.persona){
        //$oid keys can't be stored in the DB
        conv_data.buyer_company["id"] = conv_data.buyer_company["_id"]["$oid"]
        conv_data.persona["id"] = conv_data.persona["_id"]["$oid"]
        delete conv_data.buyer_company["_id"]
        delete conv_data.persona["_id"]
      }

      axios.post(BACKEND_ADDRESS + '/evaluate/generate_url/', conv_data)
      .then((response) => {
        history.push('/attempt/' + response.data["id"])
      }).catch((e) => {
        console.log(e)
        //setRoleplayState(0) //setting here because setting it when you are redirecting to scoring page can cause errors
      })
    //}
    // }else{ //to show popup for user to decide whether they want to evaluate conversation or not
    //   setRoleplayState(0)
    //   setShowScore(true)
    //   setConvId(convId)
    // }
  }

  const storeConversation = (val, convId) => {
    var processedVal = val.map(x => {return ({sender: x.sender, message: x.text})})
    setConversation((prev) => (processedVal))
    var data = {
      "email": email,
      "msgObj": processedVal,
      "id": convId
    }

    axios.post(BACKEND_ADDRESS + '/store_conversation/', data)
    .then((response) => {
      console.log("storeConversation ran!")
    }).catch(error => console.error(error));
  }

  const style = theme => ({
    paper0: {border: "1px", borderRadius: "32px", padding: "24px", position: "relative", overflowY: "auto"},
    paper: {height: "69vh", border: "1px", borderRadius: "16px", padding: "24px", position: "relative", overflowY: "auto"},
    //paper1: {height: "71vh", border: "1px", borderRadius: "16px", position: "relative", overflowY: "auto"}
  });

  const classes = useClasses(style);
  var roleplay_metadata;

  if(trigger !== null && activePersona !== null && activeCompany !== null && triggerMessage !== "" && sellerCompany !== null && applicableObjections != null){
    roleplay_metadata = {
      buyer_company: activeCompany,
      seller_company: activeCompany["is_template"]?activeCompany.seller_company:sellerCompany,
      persona: activePersona,
      play_content: props.playContent,
      trigger: trigger,
      portkey: true, //by default its false,
      initial_message: triggerMessage,
      trigger_description: triggerDescription,
      objections: applicableObjections
    }
  }
  else{
    roleplay_metadata = {}
  }

  var rating
  if(state === "evaluation" && evaluation && evaluation.evaluation && evaluation.evaluation.evaluation){
    rating = getRoleplayVerdict(evaluation["evaluation"])
  }

  return (
    state === "start" && buyerCompanies.length === 0?
      <Dialog open={buyerCompanies.length === 0} onClose={() => history.push("/companies")}>
        <DialogTitle>
          You have not created any Buyer Company yet
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Adding a buyer company helps us in imitating your actual prospect. Go ahead and create a buyer company before you start a roleplay.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => history.push("/companies")} autoFocus sx={{textTransform: "none"}}>Create Buyer Company</Button>
        </DialogActions>
      </Dialog>
    :
    (data["name"] !== undefined && activePersona !== null && activeCompany !== null) || state === "evaluation"?
      <Grid container direction="row" spacing={1} justifyContent="center">
        {state === "start" && showScore?
          <Dialog open={showScore} onClose={() => setShowScore(false)}>
            <DialogContent>
              <DialogContentText>
                The prospect ended the roleplay!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => {setShowScore(false);setOid(objectId())}} autoFocus sx={{textTransform: "none"}}><ReplayIcon />&nbsp;Restart Roleplay</Button>
              <Button onClick={() => onStopRoleplay(convId, true)} sx={{textTransform: "none"}}><ScoreboardIcon />&nbsp;Evaluate Roleplay</Button>
            </DialogActions>
          </Dialog>
          :
          <></>
        }

        {state === 'roleplay' || state === 'start'?
        <Grid item xs={12} lg={7}>
          <Paper className={classes.paper0} elevation={4} sx={{backgroundColor: "#fcfbff"}}>
            <Grid container direction="row" spacing={4} justifyContent="space-between" alignItems="center">
              <Grid item xs={6}>
                {triggerDescription === ""?
                <>
                <div align="center" style={{marginBottom: "12px"}}><Skeleton variant="circular" width={150} height={150} /></div>
                  <Grid container direction="row" spacing={1}>
                    <Grid item xs={12}><Skeleton /></Grid>
                    <Grid item xs={12}><Skeleton /></Grid>
                    <Grid item xs={6}><Skeleton /></Grid>
                    <Grid item xs={6}><Skeleton /></Grid>
                    <Grid item xs={6}><Skeleton /></Grid>
                    <Grid item xs={6}><Skeleton /></Grid>
                  </Grid>
                  <br />
                </>
                :
                <>
                <RippleAvatar key={roleplayState} size={150} status={roleplayState} photo={activePersona["photo"]}/>
                <center>
                  <Typography variant="h6" color="primary"><b>{activePersona["name"]}</b></Typography>
                  <Stack alignItems="center" direction="row" justifyContent="center">
                    <Typography variant="body1" display="inline"><i>{activeCompany["prospect_designation"]} at <b><u>{activeCompany["company"]}</u></b></i></Typography>
                    <NavLink to={"/companies/" + activeCompany["_id"]["$oid"]} target="_blank">
                      <OpenInNewIcon color="primary"/>
                    </NavLink>
                  </Stack>
                </center>
                <Grid container direction="row" item alignItems="center" justifyContent="center">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={5}>
                    {buyingStyleDesign(activePersona["tags"]["buying_style"])}
                  </Grid>
                  <Grid item xs={5}>
                    {relationshipDesign(activePersona["tags"]["relationship"])}
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={5}>
                    {talkingStyleDesign(activePersona["tags"]["talking_style"])}
                  </Grid>
                  <Grid item xs={5}>
                    {brevityDesign(activePersona["tags"]["brevity"])}
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
                </>
                }
              </Grid>
              <Grid container direction="column" item xs={6} spacing={1}>
                <Grid item>
                  <Stack direction="row" alignItems="center">
                    <Tooltip title={"Practice " + props.playContent["call_stage"]}>
                      <Chip style={{color: "#604ca5"}} label={
                          <Typography variant="body1">📞&nbsp;{props.playContent["call_stage"]}</Typography>
                      }/>
                    </Tooltip>
                    &nbsp;
                    <Tooltip title={PLAY_STAGE_HELPER_CONTENT[props.playContent["play_type"]]}>
                      <Chip style={{color: "#604ca5"}} variant="outlined" label={
                        <Typography variant="body1">{emojiMapper[props.playContent["play_type"]]}&nbsp;{props.playContent["play_type"]}</Typography>
                      }/>
                    </Tooltip>
                  </Stack>
                </Grid>
                {/*
                <Grid item xs={12}>
                  <Typography variant="body1" color="primary"><b>About {activePersona["name"]} (Prospect)</b></Typography>
                </Grid>
                */}
                <Grid item>
                  <Typography variant="body1" color="primary"><b>Roleplay Objective</b></Typography>
                  <Typography variant="body1">
                    {props.playContent["description"]}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1" color="primary"><b>Call Context</b></Typography>
                  {activeCompany !== null && sellerCompany !== null?
                    <>
                    {triggerDescription === ""?
                    <Typography><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /></Typography>
                    :
                    <Typography variant="body1"><i>You are selling at <b><u>{activeCompany["is_template"]?activeCompany["seller_company"]:sellerCompany}.</u></b></i>
                    &nbsp;{triggerDescription}</Typography>}
                    </>
                    :<></>
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={1} style={{marginTop: "12px"}} justifyContent="center">
              <Grid item xs={12}>
              {trigger !== null && activePersona !== null && activeCompany !== null && triggerMessage !== "" &&
              sellerCompany !== null && applicableObjections !== null?
                 <VocodeWidget width="100%" setTranscript={storeConversation} conversationId={oid}
                 onStopRoleplay={onStopRoleplay} onStartRoleplay={onStartRoleplay} metadata={roleplay_metadata}
                 maxTimeAllowed={findMaxRoleplayTime(props.playContent)} />
               :
               <div style={{height: "56px"}}></div>
              }
              </Grid>
              <Grid item xs={10}>
                <Button variant="contained" component="label" onClick={() => generateNewCharacter(personas, buyerCompanies)}
                 startIcon={<CachedIcon />} sx={{textTransform: "none", width: "100%"}} disabled={roleplayState !== 0}>
                  Change Prospect
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button onClick={() => setCustomProspect(true)} variant="outlined"
                 sx={{textTransform: "none", width: "100%"}} disabled={roleplayState !== 0} >
                 <TuneIcon />&nbsp;Advanced
                </Button>
              </Grid>
              <Dialog open={customProspect} onClose={() => setCustomProspect(false)} fullWidth maxWidth="md">
                <DialogTitle>Set a Prospect of your choice</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <Stack direction="row" alignItems="center">
                      <FormControl required={true}>
                        <FormLabel style={{color: "#604ca5"}}>Persona</FormLabel>
                        <RadioGroup value={tempPersona} onChange={(e) => setTempPersona(parseInt(e.target.value))}>
                          {personas.map((val, ind) => {
                            return (
                              <FormControlLabel key={ind} value={ind} control={<Radio />} label={<>
                                <Grid container direction="row" item alignItems="center" justifyContent="center">
                                  <Grid item xs={4}></Grid>
                                  <Grid item xs={4}>
                                    <Stack direction="row" alignItems="center">
                                      <Avatar src={val["photo"]} sx={{width:64, height: 64}}/>&nbsp;{val["name"]}
                                    </Stack>
                                  </Grid>
                                  <Grid item xs={4}></Grid>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={5}>
                                      {buyingStyleDesign(val["tags"]["buying_style"])}
                                    </Grid>
                                    <Grid item xs={5}>
                                      {relationshipDesign(val["tags"]["relationship"])}
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={5}>
                                      {talkingStyleDesign(val["tags"]["talking_style"])}
                                    </Grid>
                                    <Grid item xs={5}>
                                      {brevityDesign(val["tags"]["brevity"])}
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                  </Grid>
                                  </>
                              } />
                            )
                          })}
                        </RadioGroup>
                      </FormControl>
                      <div style={{width: "30px"}}></div>
                      <Stack direction="column">
                        <FormControl required={true}>
                          <FormLabel style={{color: "#604ca5"}}>Buyer Company</FormLabel>
                          <RadioGroup value={tempCompany} onChange={e => setTempCompany(parseInt(e.target.value))}>
                            {buyerCompanies.map((val, ind) => {
                              return (
                                val["is_template"]?
                                  <FormControlLabel key={ind} value={ind} control={<Radio />} label={"Selling at " + val["seller_company"] + " to " + val["company"]}
                                  disabled={!useSampleCompany} />
                                  :
                                  <FormControlLabel key={ind} value={ind} control={<Radio />} label={"Selling at " + sellerCompany + " to " + val["company"]} />
                              )
                            })}
                          </RadioGroup>
                        </FormControl>
                        <div align="right" style={{marginTop: "36px"}}>
                        <FormControlLabel control={
                          <Switch checked={useSampleCompany} onChange={() => setUseSampleCompany(!useSampleCompany)}
                          inputProps={{ 'aria-label': 'controlled' }} />
                        } label="Use Sample Buyer Company" />
                        </div>
                      </Stack>
                    </Stack>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={setNewCharacter} autoFocus style={{textTransform: 'none'}}>Set Prospect</Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Paper>
        </Grid>
        :state === 'evaluation'?
          <Grid item xs={12} lg={4}>
            <Paper className={classes.paper} elevation={2}>
              <MeetingCTA key={showCTA} show={showCTA} callback={() => setShowCTA(false)} redirect={false}/>
              {(evaluation && "persona" in evaluation && "buyer_company" in evaluation)?
                <>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                  <Grid item>
                    <Avatar src={evaluation.persona.photo} sx={{width:64, height: 64}}/>
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2"><i>Roleplay with</i></Typography>
                    <Typography variant="body1" color="primary" ><b>{evaluation.persona.name}</b></Typography>
                    <Stack alignItems="center" direction="row">
                      <Typography variant="subtitle2">{evaluation.buyer_company.prospect_designation} at {evaluation.buyer_company.company}</Typography>
                      <NavLink to={"/companies/" + evaluation.buyer_company.id} target="_blank">
                        <OpenInNewIcon color="primary"/>
                      </NavLink>
                    </Stack>
                  </Grid>
                </Grid>
                <Stack direction="row" alignItems="center">
                  <Tooltip title={"Practice " + props.playContent["call_stage"]}>
                    <Chip style={{color: "#604ca5"}} size="small" label={
                        <Typography variant="body1">📞&nbsp;{props.playContent["call_stage"]}</Typography>
                    }/>
                  </Tooltip>
                  &nbsp;
                  <Tooltip title={PLAY_STAGE_HELPER_CONTENT[props.playContent["play_type"]]}>
                    <Chip style={{color: "#604ca5"}} size="small" variant="outlined" label={
                      <Typography variant="body1">{emojiMapper[props.playContent["play_type"]]}&nbsp;{props.playContent["play_type"]}</Typography>
                    }/>
                  </Tooltip>
                </Stack>
                <Divider style={{width: "100%", margin: "5px 0"}} />
                </>
                :<></>
              }
              {evaluation && typeof(evaluation.conversation) === typeof([])?
                <Grid container direction="column">
                  {
                    evaluation["conversation"].map((item, index) => {
                      return (
                        <Grid item key={"t" + index.toString()} justifyContent="flex-end">
                          {item.sender === "bot"?
                            <div align="left">
                              <Chip avatar={<Avatar src={"persona" in evaluation?evaluation.persona.photo:""} alt="buyer" />} label={item.message}
                              sx={{
                                height: 'auto',
                                margin: "6px 36px 6px 0px",
                                '& .MuiChip-label': {
                                  display: 'block',
                                  whiteSpace: 'normal',
                                  color: "black",
                                  fontSize: "16px",
                                  padding: "8px"
                                },
                                "& .MuiChip-avatar": {
                                  marginRight: "4px"
                                }}} />
                            </div>
                            :
                            <div align="right">
                              <Chip avatar={<Avatar />} label={item.message} variant="outlined"
                              sx={{
                                height: 'auto',
                                margin: "6px 0px 6px 36px",
                                backgroundColor: "rgba(96, 76, 165, 0.9)",
                                '& .MuiChip-label': {
                                  display: 'block',
                                  whiteSpace: 'normal',
                                  color: "white",
                                  fontSize: "16px",
                                  padding: "8px"
                                },
                                "& .MuiChip-avatar": {
                                  marginRight: "8px", order: 2
                                }}} />
                            </div>
                          }
                        </Grid>
                    )})
                  }
                </Grid>
              :
              <Grid container direction="column" justifyContent="center" alignItems="center" sx={{height: "100%"}}>
                <Grid item>
                  <Loading caption="Retrieving transcript"/>
                </Grid>
              </Grid>
              }
            </Paper>
          </Grid>
          :<></>
        }

        {state === 'evaluation'?
          <Grid item xs={12} lg={8}>
            <Paper className={classes.paper} elevation={2}>
              {evaluation === null?
                <Grid container direction="column" justifyContent="center" alignItems="center" sx={{height: "100%"}}>
                  <Grid item>
                    <Loading caption="Evaluating roleplay.."/>
                  </Grid>
                </Grid>
                :
                <>
                  {"coach" in evaluation.evaluation || Object.keys(evaluation.evaluation.evaluation).length === 0?
                    <>
                      <Grid container direction="row" justifyContent="center" alignItems="center" alignContent="center">
                        {//}<Grid item xs={2}></Grid>
                        }
                        <Grid item xs={12}>
                        <center>
                        {//<Typography variant="h5" color="primary" style={{marginBottom: "48px"}}><b>Overall Performance</b></Typography>
                        }
                        <Typography variant="h5" sx={{color: (rating > 2)?"red":"green"}}><b>{OVERALL_EVALUATION[rating]["category"]}</b></Typography>
                        <Typography variant="body1" style={{marginBottom: "32px"}}>{OVERALL_EVALUATION[rating]["comment"]}</Typography>
                        <NavLink to={"/all_tracks/" + props.t_id + "/plays/" + props.m_id}>
                          <Button variant="contained" color="primary"
                          style={{textTransform: "none", width: "100%", marginBottom: "6px"}}>
                            Try the same roleplay again!
                          </Button>
                        </NavLink>
                        <NavLink to={"/all_tracks/" + props.t_id}>
                          <Button variant="outlined" color="primary"
                          style={{textTransform: "none", width: "100%", marginBottom: "48px"}}>
                            New Roleplay
                          </Button>
                        </NavLink>
                        {/*<Typography variant="h5" color="primary" style={{marginBottom: "12px"}}><b>Analysis</b></Typography>
                        */}
                        </center>
                        </Grid>
                        {//<Grid item xs={2}></Grid>
                        }
                      </Grid>
                      <Grid container direction="row" justifyContent="space-between">
                        <Grid item>
                          <Typography variant="h6" color="primary" style={{marginBottom: "12px"}}>
                            <b>Coach Remarks&nbsp;</b>
                          </Typography>
                        </Grid>
                        {/*<Grid item>
                          <NavLink to={"/all_tracks/" + props.t_id + "/plays/" + props.m_id}>
                            <Button variant="contained" color="primary"
                            style={{textTransform: "none"}}>
                              Try Again!
                            </Button>
                          </NavLink>
                          &nbsp;<b>or</b>&nbsp;
                          <NavLink to={"/all_tracks/" + props.t_id}>
                            <Button variant="outlined" color="primary"
                            style={{textTransform: "none"}}>
                              New Play
                            </Button>
                          </NavLink>
                        </Grid>
                        */}
                      </Grid>
                      {Object.keys(evaluation.evaluation.evaluation).length > 0?
                        <Typography variant="body1">
                          {evaluation.evaluation["coach"]}
                        </Typography>
                        :
                        <Typography variant="body1">
                          Your roleplay is too short to be evaluated properly. Try again!
                        </Typography>
                      }
                      <br /><br />
                    </>
                    :<></>
                  }

                  {Object.keys(evaluation.evaluation.evaluation).length > 0?
                  <>
                    <Typography variant="h6" color="primary" style={{marginBottom: "12px"}}>
                      <b>How did you do?</b>
                    </Typography>
                    {evaluation.evaluation.type === FOLLOW_TALKING_POINTS?
                      <Typography variant="body1">In this roleplay, your objective was to address several key talking points. Here's how you did-</Typography>
                      :
                      evaluation.evaluation.type === SELL_TO_A_PERSONA?
                        <Typography variant="body1">In this roleplay, you had to do an end to end roleplay. Here's how it turned out-</Typography>
                        :
                        <Typography variant="body1">In this roleplay, you were placed in a situation and your objective was to deal with it. Here's what you did right and what you didn't-</Typography>
                    }
                    <br />
                    <Grid container direction="column">
                      {Object.keys(evaluation["evaluation"]["evaluation"]).map((tp, ind) => {
                        return (
                          <Grid key={ind} container item direction="row">
                            <Grid container direction="column" spacing={1} item xs={10}>
                              <Grid container direction="row" alignItems="center" item>
                                <Grid item>
                                    <b>
                                    {"type" in evaluation["evaluation"]["evaluation"][tp]?
                                      <Chip style={{color: "#604ca5"}} size="small" label={evaluation["evaluation"]["evaluation"][tp]["type"]}/>
                                      :<></>
                                    }
                                    &nbsp;{tp}
                                    </b>&nbsp;
                                    <IconButton onClick={()=>setShowCTA(true)}>
                                      <EditIcon color="primary" sx={{height:"18px", width:"18px"}} />
                                    </IconButton>
                                </Grid>
                              </Grid>
                              <Grid item>
                              {typeof(evaluation["evaluation"]["evaluation"][tp]) === 'object'?
                                <Typography variant="subtitle2" style={{marginLeft: "6px"}}>{evaluation["evaluation"]["evaluation"][tp]["comments"]}</Typography>
                                :
                                <></>
                              }
                              </Grid>
                            </Grid>
                            <Grid container direction="row" item xs={2} justifyContent="center" alignItems="center">
                              <Grid item>
                              {typeof(evaluation["evaluation"]["evaluation"][tp]) === 'object'?
                                evaluation["evaluation"]["evaluation"][tp]['result'] === 'SATISFACTORY'?
                                  <CheckCircleIcon fontSize="large" style={{color: "green"}}/>
                                  :
                                  <CancelIcon fontSize="large" style={{color: "red"}}/>
                                :
                                evaluation["evaluation"]["evaluation"][tp]?
                                  <CheckCircleIcon fontSize="large" style={{color: "green"}}/>
                                  :
                                  <CancelIcon fontSize="large" style={{color: "red"}}/>
                              }
                              </Grid>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider style={{width: "100%", margin: "5px 0"}} />
                            </Grid>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </>:<></>
                  }
                </>
              }
            </Paper>
          </Grid>
          :
          <></>
        }
      </Grid>
    :
      <></>
  )
}
