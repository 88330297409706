import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CallEndIcon from '@mui/icons-material/CallEnd';
import CallIcon from '@mui/icons-material/Call';

import React, {useState, useEffect} from "react";
import { useConversation, AudioDeviceConfig, ConversationConfig } from "./../index.js";
//import { useConversation } from "./../index.js";
//import { isMobile } from "react-device-detect";

import {formatTime} from "./../../utils/helper"
import { useSelector } from 'react-redux'
import {Typography} from '@mui/material';

// look here for how this form of prop sending work- https://chat.openai.com/share/3eb85048-4d5d-4b71-8e72-a74555ab039f
const Conversation = ({
  config,
  height,
  width,
  setTranscript,
  onStopRoleplay,
  onStartRoleplay,
  metadata,
  maxTimeAllowed
}: {
  config: Omit<ConversationConfig, "audioDeviceConfig">,
  height: str,
  width: str,
  setTranscript: any,
  onStopRoleplay: any,
  onStartRoleplay: any,
  metadata: any,
  maxTimeAllowed: number
}) => {
  const [audioDeviceConfig, setAudioDeviceConfig] =
    React.useState({});
  //const [inputDevices, setInputDevices] = React.useState<MediaDeviceInfo[]>([]);
  //const [outputDevices, setOutputDevices] = React.useState<AudioDeviceConfig[]>([]);
  const email = useSelector((state) => state.email.value);
  const [timeRemaining, setTimeRemaining] = useState(maxTimeAllowed);

  const resetTimer = () => {
    setTimeRemaining(maxTimeAllowed)
  }

  const { status, start, stop, analyserNode, transcripts } = useConversation(
    ["transcriberConfig", "agentConfig", "synthesizerConfig", "vocodeConfig"]
    .every((key) => key in config)?
    Object.assign(config,
      { audioDeviceConfig }, {
        transcriptFunction: setTranscript,
        onStopRoleplay: onStopRoleplay,
        onStartRoleplay: onStartRoleplay,
        resetTimer: resetTimer
      })
    :{
      backendUrl: process.env.REACT_APP_VOCODE_BACKEND,
      subscribeTranscript: true,
      audioDeviceConfig,
      conversationId: email,
      transcriptFunction: setTranscript,
      onStopRoleplay: onStopRoleplay,
      onStartRoleplay: onStartRoleplay,
      resetTimer: resetTimer
    }
  );

  // const {status, start, stop, error, analyserNode, transcripts}= useConversation({
  //   backendUrl: "ws://localhost:5000/conversation",
  //   subscribeTranscript: true,
  //   audioDeviceConfig,
  //   conversationId: "shalabhsingh95@gmail.com"
  // });

  //run rolplay countdown
  useEffect(() => {
    let interval = null;

    if (status === "connected" && timeRemaining > 0) {
      // Set up the interval to decrement the count every second
      interval = setInterval(() => {
        setTimeRemaining((timeRemaining) => timeRemaining - 1);
      }, 1000);
    } else if (timeRemaining === 0) {
      stop() //stop recorder
      //onStopRoleplay(config.vocodeConfig.conversationId, true) //trigger scoring flow
    }
    // Clear the interval on component unmount or if the countdown is stopped to avoid memory leaks
    return () => clearInterval(interval);
  }, [status, timeRemaining]);

  useEffect(() => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        var inputDevices = devices.filter((device) => device.deviceId && device.kind === "audioinput")
        setAudioDeviceConfig({
          ...audioDeviceConfig,
          inputDeviceId: inputDevices[0].deviceId,
          outputSamplingRate: 48000 //fixed
        })

        // setOutputDevices(
        //   devices.filter(
        //     (device) => device.deviceId && device.kind === "audiooutput"
        //   )
        // );
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <>
      <div align="right">
        <Typography><b><i>Time Remaining- {formatTime(timeRemaining)}</i></b></Typography>
      </div>
      <Button variant="contained" component="label" onClick={status === "connected" ? stop : start}
        startIcon={(status !== "connected")?(status === "connecting"?<CircularProgress size={18}/>:<CallIcon />):<CallEndIcon />} disabled={["connecting", "error"].includes(status)}
        sx={{backgroundColor: status === "connected"?"rgba(233, 0, 0, 1.0)":"rgba(68, 183, 0, 1.0)", '&:hover': {
          backgroundColor: status === "connected"?"rgba(200, 0, 0, 1.0)":"rgba(68, 153, 0, 1.0)"}, textTransform: "none", width: width, height: height}}
      >
        {(status !== "connected")?(status === "connecting"?"Dialing":"Start/Continue Call"):"End Call"}
      </Button>
    </>
  );
};

export default Conversation;
