import React, {useState, useEffect} from "react"
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {Grid, Typography} from '@mui/material';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Carousel from 'react-material-ui-carousel'
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/Edit';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Roleplay from "./roleplay/Roleplay.js"
import ToastComponent from "./../utils/ToastComponent";
import MeetingCTA from "./../utils/MeetingCTA"
import {PLAY_STAGE_HELPER_CONTENT} from "./../utils/constants"
import {FOLLOW_TALKING_POINTS, HANDLE_A_SITUATION, APPLY_A_TACTIC, BACKEND_ADDRESS,
  SELL_TO_A_PERSONA, COLD_CALL, DISCOVERY, STOPPING_CRITERIAS} from "./../utils/constants"

import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import {default as personas} from "./../utils/prospects"
import axios from "axios"

const trackHeadingStyle = {
  fontWeight: "medium", overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical'
} //all this is added to contrain heading to one line- https://stackoverflow.com/a/71324014/9160820
const trackDescriptionStyle = {
  fontWeight: "medium", overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical'
} //all this is added to contrain heading to one line- https://stackoverflow.com/a/71324014/9160820
const playHeadingStyle = {
  fontWeight: "medium", overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical'
} //all this is added to contrain heading to one line- https://stackoverflow.com/a/71324014/9160820
const playDescriptionStyle = {
  fontWeight: "medium", overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '3', WebkitBoxOrient: 'vertical'
} //all this is added to contrain heading to one line- https://stackoverflow.com/a/71324014/9160820

function TrackCard(props){
  return (
    <Paper style={{height: "160px", width: "90%", borderRadius: "8px", border: "1px", padding: "24px", position: "relative"}}
    elevation={4} sx={{':hover': {boxShadow: 10, cursor: "pointer"}}} onClick={() => props.selectTrack(props.data["_id"]["$oid"])}>
      <Typography display="inline" variant="h5" sx={trackHeadingStyle}><Typography display="inline" variant="h4" color="primary"><b>|</b></Typography> {props.data["name"]}</Typography>
      <Typography style={{paddingTop: "16px"}} sx={trackDescriptionStyle}>{props.data["description"]}</Typography>
      <div style={{position: "absolute", bottom: "10px"}}>
      <Stack direction="row" alignItems="center" flexWrap="wrap">
        <PlayCircleOutlineIcon color="primary"/>
        <Typography color="primary">&nbsp;<b>{props.data["play_list"].length} Roleplays</b></Typography>
      </Stack>
      </div>
    </Paper>
  )
}

function TrackCardEditable(props){
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {setOpen(true)}
  const handleClose = () => {setOpen(false)}

  return (
    <>
    <Paper style={{height: "160px", width: "90%", borderRadius: "8px", border: "1px", padding: "24px", position: "relative"}}
     elevation={4} >
      <IconButton style={{position:"absolute", right: "42px"}} onClick={() => props.selectTrack(props.data["_id"]["$oid"])}><EditIcon/></IconButton>
      <IconButton style={{position:"absolute", right: "12px"}} onClick={handleClickOpen}><DeleteIcon/></IconButton>
      <Typography display="inline" variant="h5" sx={trackHeadingStyle}><Typography display="inline" variant="h4" color="primary"><b>|</b></Typography> {props.data["name"]}</Typography>
      <Typography style={{paddingTop: "16px"}} sx={trackDescriptionStyle}>{props.data["description"]}</Typography>
      <div style={{position: "absolute", bottom: "10px"}}>
        <Typography color="primary"><b>{props.data["play_list"].length} Roleplays</b></Typography>
      </div>
    </Paper>
    <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Are you sure you want to delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            If this track is already deployed, your team members might have already
            attempted roleplays in this track. Their progress will be lost if you delete this track.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Cancel</Button>
          <Button onClick={handleClose} style={{color: "red"}}>Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

function AddTrackCard(props){
  return (
    <Paper style={{height: "160px", width: "90%", borderRadius: "8px", border: "1px", borderStyle: "dashed", padding: "24px", backgroundColor: "#fbfafe"}}
    elevation={4} sx={{':hover': {boxShadow: 10, cursor: "pointer"}}} onClick={props.onClick}>
      <Typography display="inline" variant="h5">Add New Track</Typography>
      <Typography style={{padding: "16px 0px"}}>Create a new track from an existing track template or start from scratch</Typography>
      <center><ControlPointIcon color="primary" fontSize="large"/></center>
    </Paper>
  )
}

var emojiMapper = {
  [FOLLOW_TALKING_POINTS]: <>📋</>,
  [HANDLE_A_SITUATION]: <>🚧</>,
  [APPLY_A_TACTIC]: <>🪄</>,
  [SELL_TO_A_PERSONA]: <>🧑🏻</>
}

function PlayCard(props){
  return (
    <Paper style={{height: "180px", width: "90%", borderRadius: "8px", border: "1px", padding: "24px", position: "relative"}}
    elevation={4} sx={{':hover': {boxShadow: 10, cursor: "pointer"}}} onClick={() => props.selectPlay(props.data["_id"]["$oid"])}>
      <Typography display="inline" variant="h6" sx={playHeadingStyle} ><b>{props.data["name"]}</b></Typography>
      <Typography variant="body1" style={{paddingTop: "16px"}} sx={playDescriptionStyle}>{props.data["description"]}</Typography>
      <div style={{position: "absolute", bottom: "10px"}}>
        <Stack direction="row" alignItems="center" flexWrap="wrap">
          <Tooltip title={"Practice " + props.data["call_stage"]}>
            <Chip style={{color: "#604ca5"}} label={
                <Typography variant="body1">📞&nbsp;{props.data["call_stage"]}</Typography>
            }/>
          </Tooltip>
          &nbsp;
          <Tooltip title={PLAY_STAGE_HELPER_CONTENT[props.data["play_type"]]}>
            <Chip style={{color: "#604ca5"}} variant="outlined" label={
              <Typography variant="body1">{emojiMapper[props.data["play_type"]]}&nbsp;{props.data["play_type"]}</Typography>
            }/>
          </Tooltip>
        </Stack>
      </div>
    </Paper>
  )
}

function PlayCardEditable(props){
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {setOpen(true)}
    const handleClose = () => {setOpen(false)}

  return (
    <>
    <Paper style={{height: "180px", width: "90%", borderRadius: "8px", border: "1px", padding: "24px", position: "relative"}}
    elevation={4}>
      <IconButton style={{position:"absolute", right: "42px"}} onClick={() => props.selectPlay(props.data["_id"]["$oid"])}><EditIcon/></IconButton>
      <IconButton style={{position:"absolute", right: "12px"}} onClick={handleClickOpen}><DeleteIcon/></IconButton>
      <div style={{marginRight: "48px"}}><Typography display="inline" variant="h6" sx={playHeadingStyle}><b>{props.data["name"]}</b></Typography></div>
      <Typography variant="body1" style={{paddingTop: "16px"}} sx={playDescriptionStyle}>{props.data["description"]}</Typography>
      <div style={{position: "absolute", bottom: "10px"}}>
        <Stack direction="row" alignItems="center" flexWrap="wrap">
          <Tooltip title={"Practice " + props.data["call_stage"]}>
            <Chip style={{color: "#604ca5"}} label={
                <Typography variant="body1">📞&nbsp;{props.data["call_stage"]}</Typography>
            }/>
          </Tooltip>
          &nbsp;
          <Tooltip title={PLAY_STAGE_HELPER_CONTENT[props.data["play_type"]]}>
            <Chip style={{color: "#604ca5"}} variant="outlined" label={
              <Typography variant="body1">{emojiMapper[props.data["play_type"]]}&nbsp;{props.data["play_type"]}</Typography>
            }/>
          </Tooltip>
        </Stack>
      </div>
    </Paper>
    <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Are you sure you want to delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            If this track is already deployed, your team members might have already
            attempted this play. Their progress will be lost if you delete this play.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>Cancel</Button>
          <Button onClick={handleClose} style={{color: "red"}}>Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

function PlayCardReadOnly(props){
  return (
    <Paper style={{height: "180px", width: "90%", borderRadius: "8px", border: "1px", padding: "24px", position: "relative"}}
    elevation={1}>
      <Typography display="inline" variant="h6" ><b>{props.data["name"]}</b></Typography>
      <Typography variant="body1" style={{paddingTop: "16px"}}>{props.data["description"]}</Typography>
      <div style={{position: "absolute", bottom: "10px"}}>
        <Stack direction="row" alignItems="center" flexWrap="wrap">
          <Tooltip title={"Practice " + props.data["call_stage"]}>
            <Chip style={{color: "#604ca5"}} label={
                <Typography variant="body1">📞&nbsp;{props.data["call_stage"]}</Typography>
            }/>
          </Tooltip>
          &nbsp;
          <Tooltip title={PLAY_STAGE_HELPER_CONTENT[props.data["play_type"]]}>
            <Chip style={{color: "#604ca5"}} variant="outlined" label={
              <Typography variant="body1">{emojiMapper[props.data["play_type"]]}&nbsp;{props.data["play_type"]}</Typography>
            }/>
          </Tooltip>
        </Stack>
      </div>
    </Paper>
  )
}

function AddPlayCard(props){
  return (
    <Paper style={{height: "180px", width: "90%", borderRadius: "8px", border: "1px", borderStyle: "dashed", padding: "24px", backgroundColor: "#fbfafe"}}
    elevation={4} sx={{':hover': {boxShadow: 10, cursor: "pointer"}}} onClick={props.onClick}>
      <Typography display="inline" variant="h6"><b>Add New Play</b></Typography>
      <Typography variant="body1" style={{padding: "16px 0px"}}>Create a new play from an existing play template or start from scratch</Typography>
      <center><ControlPointIcon color="primary" fontSize="large"/></center>
    </Paper>
  )
}

function Plays(props){
  const playContent = props.playContent

  const buyerCompanies = props.buyerCompanies
  const triggerList = props.triggerList
  const prospects = props.prospects
  var i

  //setting default values
  var defaultCompany = {}, defaultTrigger = "";
  if(playContent['buyer_company'] === ""){
    defaultCompany = {}
  }else{
    for(i=0;i<buyerCompanies.length;i+=1){
      if(playContent["buyer_company"] === buyerCompanies[i]["_id"]["$oid"]){
        defaultCompany = buyerCompanies[i]
      }
    }
  }
  if(playContent['trigger'] === ""){
    defaultTrigger = ""
  }else{
    for(i=0;i<triggerList.length;i+=1){
      if(playContent["trigger"] === triggerList[i]["_id"]["$oid"]){
        defaultTrigger = triggerList[i]["_id"]["$oid"]
      }
    }
  }

  var playProspect;
  if(playContent['persona_id'] === ""){
    playProspect = 0
  }else{
    for(i=0;i<prospects.length;i+=1){
      if(playContent["persona_id"] === prospects[i]["_id"]["$oid"]){
        playProspect = i
      }
    }
  }

  var tpc = []
  if("talking_points" in playContent){
    for(i=0;i<playContent["talking_points"].length;i+=1){
      tpc.push({topic: playContent["talking_points"][i], stopping_criteria: playContent["stopping_criteria"][i]})
    }
  }else{
    tpc.push({topic: "", stopping_criteria: ""}) //one blank entry should be there
  }

  //setting up blank values
  if(!('how_to_handle' in playContent)){
    playContent['how_to_handle'] = [""]
  }
  if(!('how_not_to_handle' in playContent)){
    playContent['how_not_to_handle'] = [""]
  }
  if(!('script_how_to' in playContent)){
    playContent['script_how_to'] = []
  }
  if(!('script_how_not_to' in playContent)){
    playContent['script_how_not_to'] = []
  }

  const [playStage, setPlayStage] = useState(playContent['play_type'])
  const [callStage, setCallStage] = useState(playContent['call_stage'])
  const [playStageHelper, setPlayStageHelper] = useState(PLAY_STAGE_HELPER_CONTENT[playContent['play_type']])
  const [playName, setPlayName] = useState(playContent['name'])
  const [buyerCompany, setBuyerCompany] = useState(playContent['buyer_company'])
  const [triggerSituation, setTriggerSituation] = useState(playContent['trigger'])
  const [topics, setTopics] = useState(tpc)
  const [howTo, setHowTo] = useState(playContent['how_to_handle'])
  const [howNotTo, setHowNotTo] = useState(playContent['how_not_to_handle'])
  const [scriptHowTo, setScriptHowTo] = useState(playContent['script_how_to'])
  const [scriptHowNotTo, setScriptHowNotTo] = useState(playContent['script_how_not_to'])

  const handlePlayNameChange = (e) => {
    setPlayName(e.target.value)
  }
  const handlePlayStage = (event) => {
    setPlayStageHelper(PLAY_STAGE_HELPER_CONTENT[event.target.value]);
    setPlayStage(event.target.value)
  }
  const handleCallStage = (event) => {
    setCallStage(event.target.value)
  }
  const handleStoppingCriteriaChange = (e, ind) => {
    var newTopics = topics;
    newTopics[ind]["stopping_criteria"] = e.target.value
    setTopics(newTopics)
  }
  const handleTopicChange = (e, ind) => {
    var newTopics = topics;
    newTopics[ind]["topic"] = e.target.value
    setTopics(newTopics)
  }
  const handleRemoveTopic = (ind) => {
    var newTopics = topics;
    newTopics.splice(ind, 1)
    setTopics(newTopics)
  }
  const handleAddTopic = (ind) => {
    var newTopics = topics;
    newTopics.splice(ind, 0, {topic: "", stopping_criteria: "Seller covers the point"})
    setTopics(newTopics)
  }
  const handleBuyerCompanyChange = (e) => {
    setBuyerCompany(e.target.value)
  }

  const handleTriggerChange = (e) => {
    setTriggerSituation(e.target.value)
  }
  const handleHowToChange = (e, ind) => {
    var newHowTo = howTo
    newHowTo[ind] = e.target.value
    setHowTo(newHowTo)
  }
  const handleAddHowTo = (ind) => {
    var newHowTo = howTo;
    newHowTo.splice(ind+1, 0, "")
    setHowTo(newHowTo)
  }
  const handleRemoveHowTo = (ind) => {
    var newHowTo = howTo;
    newHowTo.splice(ind, 1)
    setHowTo(newHowTo)
  }
  const handleHowNotToChange = (e, ind) => {
    var newHowNotTo = howNotTo
    newHowNotTo[ind] = e.target.value
    setHowNotTo(newHowNotTo)
  }
  const handleAddHowNotTo = (ind) => {
    var newHowNotTo = howNotTo;
    // console.log(newHowNotTo)
    newHowNotTo.splice(ind+1, 0, "")
    //console.log(newHowNotTo)
    setHowNotTo(newHowNotTo)
  }
  const handleRemoveHowNotTo = (ind) => {
    var newHowNotTo = howNotTo;
    newHowNotTo.splice(ind, 1)
    setHowNotTo(newHowNotTo)
  }

  const savePlay = () => {
    return //temporary code to prevent Manager role from processing "save" before the functionality has been implemented
    const talking_points = topics.map(x => x["topic"])
    const stopping_criteria = topics.map(x => x["stopping_criteria"])

    const content = {
      id: playContent["_id"]["$oid"],
      play_id: playContent["play_id"],
      is_template: playContent["is_template"],
      is_active: playContent["is_active"],
      is_draft: playContent["is_draft"],
      track_id: playContent["track_id"],
      persona_id: prospects[playProspect]["_id"]["$oid"],
      buyer_company: buyerCompany,
      trigger: triggerSituation,
      name: playContent["name"],
      description: playContent["description"],
      call_stage: callStage,
      play_type: playStage,
      talking_points: talking_points,
      stopping_criteria: stopping_criteria,
      how_to_handle: howTo,
      how_not_to_handle: howNotTo,
      how_to_scripts: scriptHowTo,
      how_not_to_scripts: scriptHowNotTo
    }

    axios.put(BACKEND_ADDRESS + '/plays/get_play/', content)
    .then((response) => {
      ToastComponent("info", "Play updated!", "#604ca5")
    }).catch(error => {
      console.error(error);
      ToastComponent("error", "Couldn't update play")
    })
  }

  return (
    <Grid container direction="row" spacing={1} style={{width: "60vw"}}>
      <Grid item xs={6}>
        <Paper style={{borderRadius: "8px", border: "1px", padding: "24px"}} elevation={1}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <TextField label="Play Name" defaultValue={playName} variant="standard"
              required sx={{width: "100%"}} InputLabelProps={{sx: {color: "#604ca5"}}}
              disabled={playName === ""?false:true} onChange={e => handlePlayNameChange(e)}/>
            </Grid>
            <Grid item>
              <FormControl required={true}>
                <FormLabel style={{color: "#604ca5"}}>Call Stage</FormLabel>
                <RadioGroup defaultValue={callStage} onChange={handleCallStage}>
                  <FormControlLabel value={COLD_CALL} control={<Radio />} label={COLD_CALL} />
                  <FormControlLabel value={DISCOVERY} control={<Radio />} label={DISCOVERY} />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl required={true}>
                <FormLabel style={{color: "#604ca5"}}>Play Type</FormLabel>
                <RadioGroup defaultValue={playStage} onChange={handlePlayStage} required={true}>
                  <FormControlLabel value={FOLLOW_TALKING_POINTS} control={<Radio />} label={FOLLOW_TALKING_POINTS} />
                  <FormControlLabel value={HANDLE_A_SITUATION} control={<Radio />} label={HANDLE_A_SITUATION} />
                  <FormControlLabel value={APPLY_A_TACTIC} control={<Radio />} label={APPLY_A_TACTIC} />
                  <FormControlLabel value={SELL_TO_A_PERSONA} control={<Radio />} label={SELL_TO_A_PERSONA} />
                </RadioGroup>
                <FormHelperText style={{color: "#604ca5"}}>{playStageHelper}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={6} >
        <Paper style={{borderRadius: "8px", border: "1px", height: "100%"}} elevation={1}>
          <Carousel index={playProspect} navButtonsAlwaysVisible={true} autoPlay={false} style={{height: "100%", overflow: "none"}}
          indicators={false} navButtonsProps={{style: {margin: "0 0", opacity: "0.2"}}}
          next = {() => {playProspect = playProspect + 1 === prospects.length? 0: playProspect + 1;}}
          prev = {() => {playProspect = playProspect - 1 === -1? prospects.length - 1: playProspect - 1;}} >
          {
            prospects.map((item, i) => {
              return (
                <center>
                  <Grid container direction="column" key={i}>
                    <Grid item style={{background: "#f6f4ff", padding: "25px 0px"}}>
                      <img src={personas[item["_id"]["$oid"]]["photo"]} width="100" height="100" alt="software buyer persona"/>
                      <Typography><b>{item["name"]}</b></Typography>
                    </Grid>
                    <Divider style={{width: "100%", color: "black"}}/>
                    <Grid item style={{padding: "25px 45px"}}>
                      <div align="left">
                        {item["description"]}
                      </div>
                    </Grid>
                  </Grid>
                </center>
              )
            })
          }
          </Carousel>
        </Paper>
      </Grid>
      {playStage === FOLLOW_TALKING_POINTS?
        <Grid item xs={12}>
          <Paper style={{borderRadius: "8px", border: "1px", padding: "24px"}} elevation={1}>
            <Typography variant="subtitle2" color="primary">
              Add a list of topics that the sales rep should cover in the call with the buyer (Minimum 1 topic)
            </Typography>
            <Grid container direction="row" item spacing={3} alignItems="center" style={{paddingTop: "16px"}}>
              {topics.map((val, ind) => {
                return (
                  <>
                    <Grid item xs={7}>
                      <TextField label="Topic" defaultValue={val["topic"]} variant="standard" required sx={{width: "100%"}} InputLabelProps={{sx: {color: "#604ca5"}}} onChange={e => handleTopicChange(e, ind)} multiline={true}/>
                    </Grid>
                    <Grid item xs={5}>
                      <FormControl variant="standard" style={{width: "75%"}}>
                        <InputLabel style={{color: "#604ca5"}}>Stopping Criteria</InputLabel>
                        <Select value={topics[ind]["stopping_criteria"]} onChange={(e) => handleStoppingCriteriaChange(e, ind)} defaultValue={playContent["stopping_criteria"]}>
                          {STOPPING_CRITERIAS.map(x => {
                            return (
                              <MenuItem value={x}>{x}</MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                      <IconButton size="small" onClick={() => handleAddTopic(ind)}><AddIcon /></IconButton>
                      <IconButton size="small" onClick={() => handleRemoveTopic(ind)}><RemoveIcon /></IconButton>
                    </Grid>
                  </>
                )
              })}
            </Grid>
          </Paper>
        </Grid>
        :
        <></>
      }
      {playStage === HANDLE_A_SITUATION || playStage === APPLY_A_TACTIC?
        <>
          <Grid item xs={12}>
            <Paper style={{borderRadius: "8px", border: "1px", padding: "24px"}} elevation={1}>
              <Typography variant="subtitle2  " color="primary">
                How to {playStage === HANDLE_A_SITUATION?HANDLE_A_SITUATION.toLowerCase():APPLY_A_TACTIC.toLowerCase()}?
              </Typography>
              <Grid container direction="row" item spacing={3} style={{paddingTop: "16px"}}>
                {howTo.map((val, ind) => {
                  return (
                    <Grid item xs={12}>
                      <TextField label="" defaultValue={val} variant="standard" required sx={{width: "90%"}} onChange={(e) => handleHowToChange(e, ind)} multiline={true}/>
                      <IconButton size="small" onClick={() => handleAddHowTo(ind)}><AddIcon /></IconButton>
                      <IconButton size="small" onClick={() => handleRemoveHowTo(ind)}><RemoveIcon /></IconButton>
                    </Grid>
                  )
                })}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper style={{borderRadius: "8px", border: "1px", padding: "24px"}} elevation={1}>
              <Typography variant="subtitle2" color="primary">
                How to {playStage === HANDLE_A_SITUATION?HANDLE_A_SITUATION.toLowerCase():APPLY_A_TACTIC.toLowerCase()}?
              </Typography>
              <Grid container direction="row" item spacing={3} style={{paddingTop: "16px"}}>
                {howNotTo.map((val, ind) => {
                  return (
                    <Grid item xs={12}>
                      <TextField label="" defaultValue={val} variant="standard" required sx={{width: "90%"}} onChange={(e) => handleHowNotToChange(e, ind)} multiline={true}/>
                      <IconButton size="small" onClick={() => handleAddHowNotTo(ind)}><AddIcon /></IconButton>
                      <IconButton size="small" onClick={() => handleRemoveHowNotTo(ind)}><RemoveIcon /></IconButton>
                    </Grid>
                  )
                })}
              </Grid>
            </Paper>
          </Grid>
        </>
      :
      <></>
      }
      <Grid item xs={12}>
        <Paper style={{borderRadius: "8px", border: "1px", padding: "24px"}} elevation={1}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <FormControl variant="standard" style={{width: "100%"}}>
                <InputLabel style={{color: "#604ca5"}}>Buyer Company</InputLabel>
                <Select value={buyerCompany} onChange={handleBuyerCompanyChange} defaultValue={defaultCompany["name"]}>
                  {buyerCompanies.map((val, ind) => {
                    return (
                    <MenuItem value={val["_id"]["$oid"]}>{val["company"] + "- " + val["description"]}</MenuItem>
                  )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="standard" style={{width: "100%"}}>
                <InputLabel style={{color: "#604ca5"}}>Trigger Situation</InputLabel>
                <Select value={triggerSituation} onChange={handleTriggerChange} defaultValue={defaultTrigger}>
                  {triggerList.map((val, ind) => {
                    return (
                      <MenuItem value={val["_id"]["$oid"]}>{val["description"]}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      {playStage === HANDLE_A_SITUATION || playStage === APPLY_A_TACTIC?
        <>
          {scriptHowTo.length > 0?
          <Grid item xs={12}>
            <Paper style={{borderRadius: "8px", border: "1px", padding: "24px"}} elevation={1}>
              <Typography variant="subtitle2" color="primary">
                Example script(s) for how to {playStage === HANDLE_A_SITUATION?HANDLE_A_SITUATION.toLowerCase():APPLY_A_TACTIC.toLowerCase()}
              </Typography>
              <Grid container direction="row" item spacing={3} style={{paddingTop: "16px"}}>
                {scriptHowTo.map((val1, ind1) => {
                  return (
                    <Grid container item xs={12}>
                      <Typography color="primary" variant="subtitle2"><b>Script {ind1+1}</b></Typography>
                      {val1.map((val2, ind2) => {
                        return (
                          <Grid container item direction="row" alignItems="center" spacing={1}>
                            <Grid item xs={2}><center><Typography color="primary">{ind2%2 === 0?"Buyer":"Seller"}</Typography></center></Grid>
                            <Grid item xs={10}>
                              <TextField label="" defaultValue={val2} variant="outlined" sx={{width: "90%"}}  multiline={true}/>
                              <IconButton size="small"><AddIcon /></IconButton>
                              <IconButton size="small"><RemoveIcon /></IconButton>
                            </Grid>
                          </Grid>
                        )
                      })}
                    </Grid>
                  )
                })}
              </Grid>
              <br />
              <Button variant="outlined" sx={{textTransform: 'none'}}>Add Script</Button>
            </Paper>
          </Grid>
          :<></>
          }
          {scriptHowNotTo.length > 0?
          <Grid item xs={12}>
            <Paper style={{borderRadius: "8px", border: "1px", padding: "24px"}} elevation={1}>
              <Typography variant="subtitle2" color="primary">
                Example script(s) for how NOT to {playStage === HANDLE_A_SITUATION?HANDLE_A_SITUATION.toLowerCase():APPLY_A_TACTIC.toLowerCase()}
              </Typography>
              <Grid container direction="row" item spacing={3} style={{paddingTop: "16px"}}>
                {scriptHowNotTo.map((val1, ind1) => {
                  return (
                    <Grid container item xs={12}>
                      <Typography color="primary" variant="subtitle2"><b>Script {ind1+1}</b></Typography>
                      {val1.map((val2, ind2) => {
                        return (
                          <Grid container item direction="row" alignItems="center" spacing={1}>
                            <Grid item xs={2}><center><Typography color="primary">{ind2%2 === 0?"Buyer":"Seller"}</Typography></center></Grid>
                            <Grid item xs={10}>
                              <TextField label="" defaultValue={val2} variant="outlined" sx={{width: "90%"}} multiline={true}/>
                              <IconButton size="small"><AddIcon /></IconButton>
                              <IconButton size="small"><RemoveIcon /></IconButton>
                            </Grid>
                          </Grid>
                        )
                      })}
                    </Grid>
                  )
                })}
              </Grid>
              <br />
              <Button variant="outlined" sx={{textTransform: 'none'}}>Add Script</Button>
            </Paper>
          </Grid>
          :<></>
          }
        </>
        :
        <></>
      }
      <Grid container item style={{justifyContent: "center"}}>
        <Button variant="contained" size="large" style={{textTransform: "none"}} onClick={savePlay}>Save</Button>
      </Grid>
    </Grid>
  )

}

function PlayTemplates(props){
  const [activeTemplate, setActiveTemplate] = useState(0);
  var template1 = []
  var template2 = []
  for (var i=0;i<props.playTemplates.length - 1; i+=1){
    //pasrsing all but last index
    if (true){
      template1.push(props.playTemplates[i])
    }else{
      template2.push(props.playTemplates[i])
    }
  }

  const onPlayClick = (ind) => {
    setActiveTemplate(ind)
    props.callback(ind)
  }

  return (
    <Paper style={{borderRadius: "8px", border: "1px", padding: "24px"}} elevation={4}>
      <Typography variant="h6" color="primary"><DashboardIcon /> TEMPLATES</Typography>
      <Typography style={{marginTop: "24px"}}><b>Play Templates</b></Typography>
      <Divider />
      <ButtonGroup orientation="vertical" variant="text" style={{width: "100%"}}>
        {template1.map((val, ind) => {
          return (
            <Button key={ind} style={{justifyContent: "flex-start", textTransform: 'none', border:0, background: (ind === activeTemplate)?'rgba(0,0,0,0.06)':'rgba(0,0,0,0)'}}
            sx={{textAlign: 'left'}} onClick={() => onPlayClick(ind)}>
              {val["name"]}
            </Button>
          )
        })}
      </ButtonGroup>

      <Typography style={{marginTop: "24px"}}><b>Plays from other Tracks</b></Typography>
      <Divider />
      {template2.length > 0?
      <ButtonGroup orientation="vertical" variant="text" style={{width: "100%"}}>
        {template2.map((val, ind) => {
          return (
            <Button key={ind} style={{justifyContent: "flex-start", textTransform: 'none', border:0, background: (ind+template1.length === activeTemplate)?'rgba(0,0,0,0.06)':'rgba(0,0,0,0)'}}
            sx={{textAlign: 'left'}}  onClick={() => onPlayClick(ind+template1.length)}>
              {val["name"]}
            </Button>
          )
        })}
      </ButtonGroup>
      :<Typography><i>Nothing to show here</i></Typography>
      }
      <center><Button variant="contained" style={{marginTop: "24px", textTransform: 'none'}} onClick={() => onPlayClick(props.playTemplates.length-1)}>No, I want to start from scratch</Button></center>
    </Paper>
  )
}

function TrackTemplates(props){
  const [activeTemplate, setActiveTemplate] = useState(0);
  var keyList1 = []
  var keyList2 = []
  const allKeys = Object.keys(props.allTrackTemplates)
  for (var i=0;i<allKeys.length - 1; i+=1){
    //parsing all but last index
    if (allKeys[i].length < 100){
      //basically nothing goes in custom tracks for now
      keyList1.push(allKeys[i])
    }else{
      keyList2.push(allKeys[i])
    }
  }

  const onTrackClick = (ind) => {
    setActiveTemplate(ind)
    props.callback(allKeys[ind])
    if(ind === allKeys.length - 1){
      //user selected to create their own track from scratch. Going to next page
      props.mode_change()
    }
  }

  return (
    <Paper style={{borderRadius: "8px", border: "1px", padding: "24px", maxHeight: "70vh", overflowY: "auto"}} elevation={4}>
      <Typography variant="h6" color="primary"><DashboardIcon /> TEMPLATES</Typography>
      <Typography style={{marginTop: "24px"}}><b>Track Templates</b></Typography>
      <Divider />
      <ButtonGroup orientation="vertical" variant="text" style={{width: "100%"}}>
        {keyList1.map((val, ind) => {
          return (
            <Button key={ind} style={{justifyContent: "flex-start", textTransform: 'none', border:0, background: (ind === activeTemplate)?'rgba(0,0,0,0.06)':'rgba(0,0,0,0)'}}
            sx={{textAlign: 'left'}} onClick={() => onTrackClick(ind)}>
              {props.allTrackTemplates[val]['name']}
            </Button>
          )
        })}
      </ButtonGroup>

      <Typography style={{marginTop: "24px"}}><b>Your Custom Tracks</b></Typography>
      <Divider />
      {keyList2.length > 0?
        <ButtonGroup orientation="vertical" variant="text" style={{width: "100%"}}>
          {keyList2.map((val, ind) => {
            return (
              <Button key={ind} style={{justifyContent: "flex-start", textTransform: 'none', border:0, background: (ind+keyList1.length === activeTemplate)?'rgba(0,0,0,0.06)':'rgba(0,0,0,0)'}}
              sx={{textAlign: 'left'}}  onClick={() => onTrackClick(ind+keyList1.length)}>
                {props.allTrackTemplates[val]['name']}
              </Button>
            )
          })}
        </ButtonGroup>
        :
        <i><Typography>Nothing to show here</Typography></i>
      }
      <center><Button variant="contained" style={{marginTop: "24px", textTransform: 'none'}} onClick={() => onTrackClick(allKeys.length-1)}>No, I want to create my own Track</Button></center>
    </Paper>
  )
}

// function BuyerCompanySelection(props){
//   const allTrackTemplates = props.allTrackTemplates
//   var initialBuyerCompany = []
//   for(var i=0;i<allTrackTemplates[props.track]['content'].length;i+=1){
//     initialBuyerCompany.push('c1')
//   }
//   const [buyerCompany, setBuyerCompany] = useState(initialBuyerCompany)
//   const [open, setOpen] = useState(props.open);
//   const handleClose = () => {
//     setOpen(false)
//     //update in backend here
//   }
//
//   const handleBuyerCompanyChange = (ind, e) => {
//     var newBuyerCompany = buyerCompany
//     newBuyerCompany[ind] = e.target.value
//     console.log(newBuyerCompany)
//     setBuyerCompany(newBuyerCompany)
//   }
//
//   const buyerCompanies = {
//     c1: {company: "Canva", description: "Canva uses automated visual testing for confidence in every product update"},
//     c2: {company: "Wag!", description: "Wag! increases test automation and device coverage cost-effectively with BrowserStack"},
//     c3: {company: "Booking.com", description: "Booking.com leverages BrowserStack to improve testing experience and product quality"},
//     c4: {company: "Rubix", description: "Rubix achieves test automation in Agile Development with BrowserStack"}
//   }
//
//   return (
//     <>
//     <Dialog open={open} onClose={handleClose} maxWidth="lg" key={buyerCompany}>
//       <DialogTitle>
//         Buyer Companies
//       </DialogTitle>
//       <DialogContent>
//         <DialogContentText>
//           {allTrackTemplates[props.track]['content'].map((val, ind) => {
//             return (
//               <Grid container direction="row" key={ind} spacing={1}>
//                 <Grid item xs={4}>
//                   <Typography variant="body1">{val["name"]}</Typography>
//                 </Grid>
//                 <Grid item xs={8}>
//                   <FormControl variant="standard" style={{width: "100%"}}>
//                     <InputLabel style={{color: "#604ca5"}}>Buyer Company</InputLabel>
//                     <Select value={buyerCompany[ind]} onChange={(e) => handleBuyerCompanyChange(ind,e)} defaultValue={buyerCompanies["c1"]["name"]}>
//                       {Object.keys(buyerCompanies).map((val2, ind2) => {
//                         return (
//                           <MenuItem key={val2} value={val2}>{buyerCompanies[val2]["company"] + "- " + buyerCompanies[val2]["description"]}</MenuItem>
//                         )
//                       })}
//                     </Select>
//                   </FormControl>
//                 </Grid>
//               </Grid>
//             )
//           })}
//         </DialogContentText>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={handleClose}>Cancel</Button>
//         <Button onClick={handleClose} autoFocus>Deploy</Button>
//       </DialogActions>
//     </Dialog>
//     </>
//   )
// }

function AddTrackFlow(props){
  //possible values- choose_track, edit_track
  const [mode, setMode] = useState("choose_track")
  const [track, setTrack] = useState(0)
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {setOpen(true)}
  const account = useSelector((state) => state.account.value);
  const [allTrackTemplates, setAllTrackTemplates] = useState(props.allTrackTemplates)

  const track_callback = (v) => {
    setTrack(v)
  }
  const set_edit_mode = (initialization) => {
    setMode("edit_track")

    if(initialization === "from_template"){
      props.callback("heading", "Edit Track")
      props.callback("description", "Customize the chosen track template as per the requirements")
    }else if(initialization === "from_scratch"){
      props.callback("heading", "Create a Track")
      props.callback("description", "Create your own customzied track as per the requirements")
    }

    window.scrollTo(0,0)
  }

  useEffect(() => {
    var pList = []
    for (var i=0;i<allTrackTemplates.length - 1; i+=1){
      pList.push(axios.get(BACKEND_ADDRESS + '/plays/get_all_plays/', {params: {account_id: account, track_id: allTrackTemplates[i]["_id"]["$oid"]}}))
    }

    Promise.all(pList)
    .then((response) => {
      for(var i=0;i<response.length;i+=1){
        var cp = allTrackTemplates
        cp[i]["plays"] = response[i]["data"]["plays"]
      }
      //updating play info in templates
      setAllTrackTemplates(cp)
      setTrack(0)
      //setting default track to render
    })
  }, [])

  return (
    <>
    {mode === "choose_track"?
      <Grid container direction="row" spacing={2}>
        <Grid item xs={3}><TrackTemplates callback={track_callback} mode_change={() => set_edit_mode("from_scratch")} allTrackTemplates={allTrackTemplates}/></Grid>
        <Grid container item direction="column" justifyContent="center" xs={9} spacing={2}>
          <Grid container item direction="row" spacing={2} style={{maxHeight: "70vh", overflowY: "auto"}}>
            {/*
              <Grid item xs={12}><Paper style={{borderRadius: "8px", border: "1px", padding: "24px"}} elevation={1}><Typography variant="h6">Plays {allTrackTemplates[track]["name"]} template</Typography></Paper></Grid>
              */
            }
            {
              "plays" in allTrackTemplates[track]?
                allTrackTemplates[track]["plays"].length > 0?
                  allTrackTemplates[track]["plays"].map((val2, ind2) => {
                    return (
                      <Grid item key={ind2} xs={12} lg={6}>
                        <PlayCardReadOnly data={val2}/>
                      </Grid>
                    )
                  })
                :
                <Grid item xs={12}><center><Typography>This track template has no roleplays!</Typography></center></Grid>
              :<></>
            }
          </Grid>
          <Grid item>
            <center>
              <Button variant="contained" style={{textTransform: 'none'}} onClick={() => set_edit_mode("from_template")}>
                Start from this Template
              </Button>
            </center>
          </Grid>
        </Grid>
      </Grid>
    :
    mode === "edit_track"?
    <>
      <Grid container direction="column" style={{marginBottom: "36px"}}>
        <Grid item>
          <TextField label="Track Name" defaultValue="" variant="standard"
          required sx={{width: "100%"}} InputLabelProps={{sx: {color: "#604ca5"}}}/>
        </Grid>
        <Grid item>
          <TextField label="Track Description" defaultValue="" variant="standard"
          required sx={{width: "100%"}} InputLabelProps={{sx: {color: "#604ca5"}}}/>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={4}>
        <Grid item xs={12} sm={6} lg={4}>
          <AddPlayCard onClick={props.addPlay}/>
        </Grid>
        {allTrackTemplates[track]['plays'].map((val, ind) => {
          return (
            <Grid item key={ind} xs={12} sm={6} lg={4}>
              <PlayCardEditable data={val} ind={ind} selectPlay={props.selectPlay}/>
            </Grid>
          )
        })}
        <Grid item xs={12}>
          <center>
            <Button variant="outlined" style={{textTransform: "none"}}>Save Draft</Button>
            &nbsp;&nbsp;
            <Button variant="contained" style={{textTransform: "none"}} onClick={handleClickOpen}>Deploy for your team</Button>
          </center>
        </Grid>
        {/*<BuyerCompanySelection allTrackTemplates={allTrackTemplates} track={track}
        key={open} open={open}/>
        */}
      </Grid>
    </>
    :
    <></>
  }
  </>)
}

export default function Tracks(props) {
  // possible values- all_tracks, all_tracks_user, track_content, add_track, play_content, add_play,
  const activePage = props.subMode
  const [play, setPlay] = useState(0)

  const play_callback = (v) => {
    setPlay(v)
  }

  let history = useHistory();
  var m_id, t_id, a_id;
  var mc_state = "start";
  //const email = useSelector((state) => state.email.value);
  const account = useSelector((state) => state.account.value);
  const role = useSelector((state) => state.role.value)

  const [allTracks, setAllTracks] = useState([])
  const [draftTracks, setDraftTracks] = useState([])
  const [templateTracks, setTemplateTracks] = useState([])
  const [trackContent, setTrackContent] = useState([])
  const [playContent, setPlayContent] = useState({})
  const [playTemplates, setPlayTemplates] = useState([])
  const [buyerCompanies, setBuyerCompanies] = useState([])
  const [triggers, setTriggers] = useState([])
  const [prospects, setProspects] = useState([])

  const [showCTA, setShowCTA] = useState(role === 'Manager'?false:true)

  if("t_id" in props.match.params){
    t_id = props.match.params.t_id
  }
  if("m_id" in props.match.params){
    m_id = props.match.params.m_id
  }
  if("a_id" in props.match.params){
    a_id = props.match.params.a_id
    mc_state = "evaluation"
  }

  const emptyPlay = {
    is_template: false,
    is_active: true,
    is_draft: false,
    track_id: [t_id],
    persona_id: "",
    buyer_company: "",
    trigger: "",
    name: "",
    description: "",
    call_stage: COLD_CALL,
    play_type: APPLY_A_TACTIC,
    how_to_handle: [""],
    how_not_to_handle: [""],
    talking_points: [""],
    stopping_criteria: [""]
    // script_how_to: [],
    // script_how_not_to: []
  }
  const emptyTrack = {
    is_template: false,
    is_active: true,
    is_draft: false,
    account_id: account,
    name: "",
    description: "",
    play_list: [],
    plays: []
  }

  useEffect(() => {
    props.callback("bg", "#fbfafe")
    window.scrollTo(0,0)

    // 1 and 2 and 6
    if(activePage === "all_tracks" || activePage === "all_tracks_user" || activePage === "add_track"){
      axios.get(BACKEND_ADDRESS + '/tracks/get_track_templates/', {})
      .then((response) => {
        var tt = response.data
        for (var i=0;i<tt.length;i+=1){
          tt[i]["plays"] = []
        }
        tt.push(emptyTrack)
        setTemplateTracks(tt)
      }).catch(error => console.error(error));
    }

    // 1 and 2
    if(activePage === "all_tracks" || activePage === "all_tracks_user"){
      axios.get(BACKEND_ADDRESS + '/tracks/get_all_tracks/', {params: {account_id: account }})
      .then((response) => {
        setAllTracks(response.data["tracks"])
      }).catch(error => console.error(error));
    }

    // 1
    if(activePage === "all_tracks"){
      axios.get(BACKEND_ADDRESS + '/tracks/get_draft_tracks/', {params: {account_id: account }})
      .then((response) => {
        setDraftTracks(response.data)
        //console.log(response.data)
      }).catch(error => console.error(error));
    }

    //3
    if(activePage === "track_content" || activePage === "track_content_user"){
      axios.get(BACKEND_ADDRESS + '/plays/get_all_plays/', {params: {account_id: account, track_id: t_id}})
      .then((response) => {
        var allPlays = response.data["plays"]

        axios.get(BACKEND_ADDRESS + '/tracks/get_track/', {params: {id: t_id }})
        .then((response) => {
          allPlays["name"] = response.data[0]["name"]
          allPlays["description"] = response.data[0]["description"]
          setTrackContent(allPlays)
        }).catch(error => console.error(error));

      }).catch(error => console.error(error));
    }

    //4
    if(activePage === "play_content" || activePage === "play_content_user"){
      if(a_id){
        axios.get(BACKEND_ADDRESS + '/plays/attempt/', {params: {id: a_id}})
        .then((r) => {
          //console.log(response.data)
          axios.get(BACKEND_ADDRESS + '/plays/get_play/', {params: {id: r.data[0]["play_id"]}})
          .then((response) => {
            var data = response.data[0]
            //setting the corresponding track id through which the attempt was created, rather than
            //multiple tracks which are possible
            data["track_id"] = ("track_id" in r.data[0])?[r.data[0]["track_id"]]: [data["track_id"][0]]
            setPlayContent(data)
          })
        }).catch(error => console.error(error));
      }else{
        axios.get(BACKEND_ADDRESS + '/plays/get_play/', {params: {id: m_id}})
        .then((response) => {
          //console.log(response.data[0])
          setPlayContent(response.data[0])
        }).catch(error => console.error(error));
      }
    }

    // 5
    if(activePage === "add_play"){
      axios.get(BACKEND_ADDRESS + '/plays/get_play_templates/', {})
      .then((response) => {
        var mt = response.data
        mt.push(emptyPlay)
        setPlayTemplates(mt)
        setPlay(0)
      }).catch(error => console.error(error));
    }

    // 4 and 5
    if(activePage === "play_content" || activePage === "add_play"){
      axios.get(BACKEND_ADDRESS + '/companies/get_company_templates/', {})
      .then((response) => {
        var allCompanies = response.data

        axios.get(BACKEND_ADDRESS + '/companies/get_all_companies/', {'params': {'account_id': account}})
        .then((response) => {
          allCompanies = allCompanies.concat(response.data);

          axios.get(BACKEND_ADDRESS + '/triggers/get_all_triggers/', {})
          .then((response) => {
            const t = response.data

            axios.get(BACKEND_ADDRESS + '/personas/get_all_personas/', {})
            .then((response) => {
              setProspects(response.data)
              setBuyerCompanies(allCompanies)
              setTriggers(t)
            }).catch(error => console.error(error));
          })
        })
      }).catch(error => console.error(error));
    }

  }, []);

  useEffect(() => {
    //setting defaults when page loads directly
    if(activePage === "all_tracks"){
      props.callback("heading", "Tracks")
      props.callback("description", "Choose an existing Track or create a new Track")
    }else if(activePage === "all_tracks_user"){
      props.callback("heading", "Tracks")
      props.callback("description", "Choose any of the following Tracks to start a roleplay")
    }else if(activePage === "track_content" || activePage === "track_content_user"){
      if(trackContent){
        props.callback("heading", trackContent["name"])
        props.callback("description", trackContent["description"])
      }
    }else if(activePage === "play_content" || activePage === "play_content_user"){
      if(playContent){
        props.callback("heading", playContent["name"])
        props.callback("description", playContent["description"])
      }
    } else if(activePage === "add_play"){
      props.callback("heading", "Define a Play")
      props.callback("description", "Create a new play from a template or start from scratch")
    } else if(activePage === "add_track"){
      props.callback("heading", "Define a Track")
      props.callback("description", "Create a new track from a template or start from scratch")
    }
  }, [activePage, trackContent, playContent])

  const selectTrack = (ind) => {
    history.push('/tracks/' + String(ind));
  }

  const selectTrackUser = (ind) => {
    history.push('/all_tracks/' + String(ind));
  }

  const selectPlay = (ind) => {
    history.push('/tracks/' + String(props.match.params.t_id) + '/plays/' + String(ind));
  }

  const selectPlayUser = (ind) => {
    history.push('/all_tracks/' + String(props.match.params.t_id) + '/plays/' + String(ind));
  }

  const addTrack = () => {
    history.push('/tracks/add_new')
  }

  const addPlay = () => {
    history.push('/tracks/' + String(props.match.params.t_id) + '/plays/add_new')
  }

    // if(Object.keys(playContent).length !== 0){
    //   console.log("track render")
    // }

  return (
    <>
    {activePage === "all_tracks"?
      <>
      <MeetingCTA key={showCTA} show={showCTA} callback={() => setShowCTA(false)}/>
      <Typography variant="h6" style={{marginBottom: "12px"}}><b>Deployed Tracks</b></Typography>
      <Grid container direction="row" spacing={4}>
        <Grid item xs={12} md={6}>
          <AddTrackCard onClick={addTrack}/>
        </Grid>
        {
          allTracks.map((val, ind) => {
            return (
              <Grid item key={ind} xs={12} md={6}>
                <TrackCardEditable data={val} ind={ind} selectTrack={selectTrack}/>
              </Grid>
            )
          })
        }
        {/*
          templateTracks.map((val, ind) => {
          return (
            <Grid item key={ind xs={12} md={6}>
              <TrackCardEditable data={val} ind={ind} selectTrack={selectTrack}/>
            </Grid>
          )
          })
        */}
      </Grid>
      <Typography variant="h6" style={{marginBottom: "12px", marginTop: "24px"}}><b>Draft Tracks</b></Typography>
        {draftTracks.length > 0?
          <Grid container direction="row" spacing={4}>
            {draftTracks.map((val, ind) => {
              return (
                <Grid item key={ind} xs={12} md={6}>
                  <TrackCardEditable data={val} ind={ind} selectTrack={selectTrack}/>
                </Grid>
              )
            })}
          </Grid>
          :
          <Typography variant="body1"><i>Nothing to show here</i></Typography>
        }
      </>
    :
    activePage === "all_tracks_user"?
      <>
        <Grid container direction="row" spacing={4}>
          {allTracks.map((val, ind) => {
            return (
              <Grid item key={ind} xs={12} md={6}>
                <TrackCard data={val} ind={ind} selectTrack={selectTrackUser}/>
              </Grid>
            )
          })}
          {/*
            templateTracks.map((val, ind) => {
            return (
              <Grid item key={ind} xs={12} md={6}>
                <TrackCard data={val} ind={ind} selectTrack={selectTrackUser}/>
              </Grid>
            )
          })
          */}
        </Grid>
      </>
    :
    activePage === "track_content"?
      <Grid container direction="row" spacing={4}>
        <Grid item xs={12} sm={6} lg={4}>
          <AddPlayCard onClick={addPlay}/>
        </Grid>
        {trackContent.map((val, ind) => {
          return (
            <Grid item key={ind} xs={12} sm={6} lg={4}>
              <PlayCardEditable data={val} ind={ind} selectPlay={selectPlay}/>
            </Grid>
          )
        })}
      </Grid>
    :
    activePage === "track_content_user"?
      <Grid container direction="row" spacing={4}>
        {trackContent.map((val, ind) => {
          return (
            <Grid item key={ind} xs={12} sm={6} lg={4}>
              <PlayCard data={val} ind={ind} selectPlay={selectPlayUser}/>
            </Grid>
          )
        })}
      </Grid>
    :
    activePage === "play_content"?
      playContent && Object.keys(buyerCompanies).length > 0 && Object.keys(triggers).length > 0 && Object.keys(prospects).length > 0?
        <Plays m_id={m_id} playContent={playContent} buyerCompanies={buyerCompanies} triggerList={triggers} prospects={prospects}/>
      :
      <></>
    :
    activePage === "play_content_user"?
      Object.keys(playContent).length !== 0?
        <Roleplay key={m_id+t_id+a_id} m_id={a_id?playContent["_id"]["$oid"]:m_id} t_id={a_id?playContent["track_id"][0]:t_id} a_id={a_id} playContent={playContent} initialState={mc_state}/>
      :
      <></>
    :
    activePage === "add_play"?
      <Grid container direction="row">
        <Grid item xs={3}><PlayTemplates playTemplates={playTemplates} callback={play_callback}/></Grid>
        <Grid container item direction="row" justifyContent="center" xs={9}>
          <Grid item>
            {
              playTemplates.length > 0?
              <Plays key={play} playContent={playTemplates[play]} buyerCompanies={buyerCompanies} triggerList={triggers} prospects={prospects}/>
              :<></>
            }
          </Grid>
        </Grid>
      </Grid>
    :
    activePage === "add_track"?
      templateTracks.length > 0?
        <AddTrackFlow callback={props.callback} allTrackTemplates={templateTracks} selectPlay={selectPlay} addPlay={addPlay}/>
      :<></>
    :
    <></>
    }
    </>
  )
}
