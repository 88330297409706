import React from "react";
import { Button, Typography } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { COLORS } from "./colors";
import {PlayCircleFilled} from "@mui/icons-material";
import './toastComponent.css';

toast.configure();

export default function ToastComponent(type,msg,callback, button_msg="Okay") {

  const CloseButton = ({ closeToast, color=COLORS.primary }) => (
    <button
      variant='contained'
      onClick={callback}
     style={{color: COLORS.white, justifySelf : 'flex-end' , alignSelf :'flex-end', backgroundColor : color, border : "none", borderRadius : 5}}
    >
    {button_msg}
    </button>
  );

  const ToastStart = (props) => {
    return (
      <>
      {props.msg}
      <center >
        <Button variant='contained' onClick={props.callback} style={{color: COLORS.white, backgroundColor : COLORS.secondary, textTransform: 'none'}}>
         Start<PlayCircleFilled />
        </Button>
      </center>
      </>
    )
  }

  if(type==="info1"){
    toast(msg, { type:type, position: toast.POSITION.TOP_CENTER, theme : "light", autoClose: false, hideProgressBar: true});
    // const width = Math.min(500, window.screen.width*0.9);
    // console.log(width)
    // toast(msg, { type:type, position: toast.POSITION.TOP_CENTER, theme : "light", closeButton: <CloseButton callback={callback} />, style: {width: width, marginLeft: (320-width)/2}, autoClose: false});
  }else if(type === "start"){
    toast(<ToastStart msg={msg} callback={callback} />, { icon: false, type:type, position: toast.POSITION.TOP_CENTER, theme : "light", autoClose: false})
  }else{
    toast(msg, { type:type, position: toast.POSITION.TOP_CENTER, theme : "light"});
  }

  // useEffect(() => {
  //     console.log('it in toastcomponent')
  //     notify();
  // })
  // return (
  //     <></>
  // )
}
