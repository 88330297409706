import React, { useState, useEffect} from "react"
import { createTheme , ThemeProvider } from '@mui/material/styles';
import "@mui/material"
//import NavBar from "./NavBar.js";
import NavBarCustom from "./NavBarCustom.js";
import Sidebar from "./Sidebar.js";
import MainContent from "./MainContent.js"
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import {AuthProvider} from "./utils/Auth";
import MeetingCTABanner from "./utils/MeetingCTABanner"
import fire, {getanalytics} from "./utils/base";
//import {Grid} from '@mui/material';

import store from './store/store.js'
import { Provider, useSelector, useDispatch } from 'react-redux'
import {setRoleValue, setEmailValue , setNameValue , setReportValue, setAccountValue} from './store/roleSlice';

import axios from "axios";
import {BACKEND_ADDRESS} from "./utils/getEndpoint.js"
import LoginContent from "./login/LoginContent.js"

const theme = createTheme({
  palette: {
    primary: {
      main: "#604CA5",
    },
    // secondary: {
    //   //main: "#E75480"
    //   //main: "#FF5733"
    // }
  },
  button: {
    borderColor: "chartreuse !important;"
  },
  typography: {
    // fontFamily: [
    //   '"Lato"',
    //   '"sans-serif"',
    // ],

    color: 'black',
    // cardText: {
    //   "fontSize":'90px',
    // },
  },
})

function GetApp() {
  let history = useHistory();
  const [onboarded, setOnboarded] = useState(null);
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [isLoggedIn, setIsLoggedIn] = useState(false) //sets isLoggedIn false so that authentication is needed again whenever getApp is opened
  const role = useSelector((state) => state.role.value)
  const dispatch = useDispatch()

  useEffect(() => {
    if(email === "" || role !== "") return

    //get role and onboarding status and store it in redux
    axios.post(BACKEND_ADDRESS + '/user_by_email/', {'email': email })
    .then((response) => {
      var allmuboards = response.data;
      console.log(email)

      if(allmuboards.length > 1){
        console.log("Multiple users found in db while authenticating")
        history.push('/signin')
      }else if(allmuboards.length === 0 || allmuboards[0].is_active === false){
        console.log("user not found in db. Setting role as guest")
        history.push('/signin')
      }else{
        //logging event in firebase
        getanalytics.logEvent("product_opened", {email: email})

        setOnboarded(allmuboards[0].onboarding);
        dispatch(setEmailValue(email));
        dispatch(setRoleValue(allmuboards[0].role));
        dispatch(setAccountValue(allmuboards[0].account_id))
        dispatch(setNameValue(allmuboards[0].name));
        dispatch(setReportValue(allmuboards[0].reports));
        console.log(email)
        window.LOU.identify(email, {
          company: allmuboards[0].account_id, // Replace this object with any user data for defining segments
          permissions: 'user', //admin/,manager/user
          plan: 'free', //free/paid
        })
      }
    })
  }, [email, role])

  fire.auth().onAuthStateChanged((user)=>{
    if(isLoggedIn) return
    const now = new Date();
    const expiry_time=now.getTime()-30*24*3600*1000;

    if(user!==null && (user.metadata.b > expiry_time )){
      setEmail(user.email)
      setName(user.displayName)
      setIsLoggedIn(true)
    }else{
      setIsLoggedIn(false)
      history.push('/signin')
    }
  });

  // initialize pendo now
  // Call this whenever information about your visitors becomes available
  // Please use Strings, Numbers, or Bools for value types.
  // if(role !== ''){
  //   window.pendo.initialize({
  //     visitor: {
  //       id: email,   // Required if user is logged in
  //       email: email,       // Recommended if using Pendo Feedback, or NPS Email
  //       full_name: name,   // Recommended if using Pendo Feedback
  //       role: role  // Optional
  //       // You can add any additional visitor level key-values here,
  //       // as long as it's not one of the above reserved names.
  //     },
  //     account: {
  //       id: 'ACCOUNT-UNIQUE-ID' // Required if using Pendo Feedback
  //       // name:         // Optional
  //       // is_paying:    // Recommended if using Pendo Feedback
  //       // monthly_value:// Recommended if using Pendo Feedback
  //       // planLevel:    // Optional
  //       // planPrice:    // Optional
  //       // creationDate: // Optional
  //
  //       // You can add any additional account level key-values here,
  //       // as long as it's not one of the above reserved names.
  //     }
  //   });
  // }

  return  (
    <div style={{display: 'flex'}} direction="column">
      {/* <NavBar />
        */}
      <NavBarCustom />
      <Sidebar />
      <MainContent boarded={onboarded}/>
      <MeetingCTABanner />
      {/*<Walkthrough />
        */}
    </div>
  );
}

function App() {
  const next_path = window.location.pathname

  return(
    <Provider store={store}>
      <AuthProvider>
        <BrowserRouter>
          <Switch>
            <Route path="/signin" component={()=> <ThemeProvider theme={theme}><LoginContent next_path = {next_path}/></ThemeProvider>} />
            <Route path="/signup" component={()=> <ThemeProvider theme={theme}><LoginContent next_path = {next_path}/></ThemeProvider>} />
            <Route path="/" component={()=> <ThemeProvider theme={theme}><GetApp /></ThemeProvider>} />
          </Switch>
        </BrowserRouter>
      </AuthProvider>
    </Provider>
  );
}

export default App;
