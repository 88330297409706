//import "@fontsource/inter";
import {useEffect, useState} from "react"
import "./App.css";
import Conversation from "./components/Conversation.tsx";
import {VOCODE_BACKEND} from "./../utils/constants"
import customVocab from "./customVocabulary"

import { isChrome, isMobile, isSafari } from "react-device-detect";
// import { WarningIcon } from "@chakra-ui/icons";
import {systemPrompt} from "./roleplaySystemPrompt.js"
import {
  DeepgramTranscriberConfig,
  ElevenLabsSynthesizerConfig,
  VocodeConfig,
  ChatGPTAgentConfig,
  CustomChatGPTAgentConfig,
  BaseMessage,
  CutOffResponse
} from "./index.js";

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from "@mui/material/Button"

const stripLength = (string) => {
  const length = 200 //strip to this length if it exceeds
  return string.length > length ? string.substring(0, length - 3) + "..." : string.substring(0, length)
}

const pickNElemets = (arr, N) => {
  // shuffle array
  for (var i = arr.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = arr[i];
      arr[i] = arr[j];
      arr[j] = temp;
  }

  //return first N numbers
  return arr.slice(0, N)
}

const VocodeWidget = (props) => {
  const initialMessage: BaseMessage = {type: "message_base", text: props.metadata.initial_message}
  const cutoffMsg1: BaseMessage = {type: "message_base", text: "I didn't hear that. Can you come again?"};
  const cutoffMsg2: BaseMessage = {type: "message_base", text: "Your voice is breaking a lot. I didn't get you at all"};
  var cutoffList: BaseMessage[] = [cutoffMsg1, cutoffMsg2]

  const cutOffResponse: CutOffResponse = {"messages": cutoffList}

  // const fillerConfig:FillerAudioConfig = {
  //   silenceThresholdSeconds: 1.5, //default value is 0.5. DO NOT TAKE THIS BEYOND 2 SECONDS
  //   usePhrases: true
  // }

  const transcriberConfig: Omit<
    DeepgramTranscriberConfig,
    "samplingRate" | "audioEncoding"
  > = {
    type: "transcriber_deepgram",
    chunkSize: 2048,
    endpointingConfig: {
      //type: "endpointing_punctuation_based",
      type: "endpointing_time_based",
      timeCutoffSeconds: 0.8 //default value is 0.4
    },
    model: "nova-2",
    keywords: customVocab
  };

  // for v4
  var role_description = ""
  if("Buyer Role Description" in Object.keys(props.metadata.buyer_company.content)){
    role_description = props.metadata.buyer_company.content["Buyer Role Description"]
  }else{
    role_description = "I work as " + props.metadata.buyer_company["prospect_designation"] + "."
  }

  var objectionString = "\n"
  const chosenObjections = pickNElemets(props.metadata.objections, 80)
  for(var i=0;i<chosenObjections.length;i+=1){
    objectionString += "Theme " + String(i+1) + ": " + stripLength(chosenObjections[i]) + "\n"
  }

  var promptPreamble = JSON.parse(JSON.stringify(systemPrompt)) //deepcopy
  promptPreamble = promptPreamble.replaceAll("{buyer_name}", props.metadata.persona["name"])
  //promptPreamble = promptPreamble.replaceAll("{buyer_designation}", props.metadata.buyer_company["prospect_designation"])
  promptPreamble = promptPreamble.replaceAll("{buyer_company}", props.metadata.buyer_company["company"])
  promptPreamble = promptPreamble.replaceAll("{seller_company}", props.metadata.seller_company)
  promptPreamble = promptPreamble.replaceAll("{about_buyer}", props.metadata.persona["prompt_description"])
  promptPreamble = promptPreamble.replaceAll("{trigger_description}", props.metadata.trigger_description)
  promptPreamble = promptPreamble.replaceAll("{cs_role_description}", role_description)
  promptPreamble = promptPreamble.replaceAll("{objection_list}", objectionString)
  //console.log(promptPreamble)

  // // for v3
  // var promptPreamble = JSON.parse(JSON.stringify(systemPrompt)) //deepcopy
  // promptPreamble = promptPreamble.replaceAll("{buyer_name}", props.metadata.persona["name"])
  // promptPreamble = promptPreamble.replaceAll("{buyer_designation}", props.metadata.buyer_company["prospect_designation"])
  // promptPreamble = promptPreamble.replaceAll("{buyer_company}", props.metadata.buyer_company["company"])
  // promptPreamble = promptPreamble.replaceAll("{seller_company}", props.metadata.seller_company)
  // promptPreamble = promptPreamble.replaceAll("{about_buyer}", props.metadata.persona["prompt_description"])
  // promptPreamble = promptPreamble.replaceAll("{trigger_description}", props.metadata.trigger_description)
  // //console.log(promptPreamble)

  // // DON'T DELETE. Old Prompt flow
  // var promptPreamble = JSON.parse(JSON.stringify(systemPrompt)) //deepcopy
  // promptPreamble = promptPreamble.replaceAll("{buyer_name}", props.metadata.persona["name"])
  // promptPreamble = promptPreamble.replaceAll("{buyer_designation}", props.metadata.buyer_company["prospect_designation"])
  // promptPreamble = promptPreamble.replaceAll("{buyer_company}", props.metadata.buyer_company["company"])
  // promptPreamble = promptPreamble.replaceAll("{about_buyer}", props.metadata.persona["prompt_description"])
  // promptPreamble = promptPreamble.replaceAll("{call_stage}", props.metadata.play_content["call_stage"])
  // var promptPreamble = "This is a sales call roleplay. You will act as the buyer in the call and I will act as the seller. There might be minor transcription errors in the roleplay, which you can ignore. Remember to act professionally as this is a sales call. Don't repeat what you say in the roleplay, no matter what. Remember to stick to your role and bring back the seller to the call agenda in case they deviate from it. Below are the call details that you may use to generate the most appropriate buyer response-\n\nBuyer Name: " + props.metadata.persona["name"] + "\nBuyer Designation: " + props.metadata.buyer_company["prospect_designation"] + "\nAbout Buyer: " + props.metadata.persona["prompt_description"] + "\nBuyer Company: " + props.metadata.buyer_company["company"] + "\nCall Stage: " + props.metadata.play_content["call_stage"]

  const agentConfig: CustomChatGPTAgentConfig = {
    type: "agent_custom_chat_gpt",
    initialMessage: initialMessage,
    //promptPreamble and modelName are not used in the backend
    promptPreamble:promptPreamble, //system prompt

    // //v3 settings
    // modelName: "ft:gpt-3.5-turbo-1106:aspiro:singular-ft-model:8z0tbeui",
    // temperature:0.5,
    // maxTokens:512,

    //v4 settings
    modelName:'ft:gpt-3.5-turbo-0125:aspiro:longsysprompt:9LYfNtAx',
    temperature:0.6,
    maxTokens:128,
    end_conversation_on_goodbye: true,

    //cutOffResponse: cutOffResponse, //response to be said when some error happens
    metadata: props.metadata,
    allowedIdleTimeSeconds: 20, //wait for extremely long before you end the roleplay
    //allowAgentToBeCutOff: true, //true by default
    sendFillerAudio: false,
  };

  var voice_id = "pNInz6obpgDQGcFmaJgB"
  if("voice_id" in props.metadata.persona){
    voice_id = props.metadata.persona.voice_id
  }

  const synthesizerConfig: Omit<
    ElevenLabsSynthesizerConfig,
    "samplingRate" | "audioEncoding"
  > = {
    type: "synthesizer_eleven_labs",
    shouldEncodeAsWav: true,
    voiceId: voice_id,
    optimizeStreamingLatency: 3, //https://help.elevenlabs.io/hc/en-us/articles/15726761640721-Can-I-reduce-API-latency
    experimentalStreaming: true,
    model_id: "eleven_turbo_v2", //https://elevenlabs.io/docs/speech-synthesis/models#turbo-v2 faster model with 400ms latency
  };
  
  // const synthesizerConfig  = {
  //   type: "synthesizer_azure",
  // };
  const vocodeConfig: VocodeConfig = {
    apiKey: "",
    baseUrl: VOCODE_BACKEND || "",
    conversationId: props.conversationId
  }

  return (
    <>
      {(isMobile || !isChrome) && !isSafari ? (
        <Dialog open={true}>
          <DialogTitle>
            Can't load Roleplay!
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
            This product works on: Chrome (desktop) and Safari (desktop) only! Please copy the current URL and open in one of those browsers to start.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" style={{textTransform: 'none'}} onClick={() => navigator.clipboard.writeText(window.location)}><ContentCopyIcon/> Copy</Button>
          </DialogActions>
        </Dialog>
      ) : (
        props.conversationId?
          <Conversation
            config={{
              transcriberConfig,
              agentConfig,
              synthesizerConfig,
              vocodeConfig,
            }}
            width={props.width}
            height={props.height}
            setTranscript={props.setTranscript}
            onStopRoleplay={props.onStopRoleplay}
            onStartRoleplay={props.onStartRoleplay}
            metadata={props.metadata}
            maxTimeAllowed={props.maxTimeAllowed}
          />
          :<></>

      )}
    </>
  );
};

export default VocodeWidget;
