const COLD_CALL = "Cold Call"
const DISCOVERY = "Discovery"

const FOLLOW_TALKING_POINTS = "Follow Talking Points"
const HANDLE_A_SITUATION = "Handle a Situation"
const APPLY_A_TACTIC = "Apply a Tactic"
const SELL_TO_A_PERSONA = "Sell to a Persona"

const PLAY_STAGE_HELPER_CONTENT = {
  [FOLLOW_TALKING_POINTS]: "Follow through a list of topics while conducting sections of a call",
  [HANDLE_A_SITUATION]: "Handle a situation in the call to avoid common pitfalls",
  [APPLY_A_TACTIC]: "Apply a technique to smoothly sail through a specific call section or situation",
  [SELL_TO_A_PERSONA]: "Role-play at length to be ready for a Persona's tendencies & quirks",
}

const DEFAULT_TRACKS = [
  "654a4da697e3ad87c3fce621", //sdr basic track,
  "654b6a7697e3ad87c3fce639", //sdr talking to different personas,
  "654a68d497e3ad87c3fce622", //sdr best practices
]

const STOPPING_CRITERIAS = [
  "Seller covers the point",
  "Prospect acknowledges the point",
  "Prospect stays on point till satisfied by seller"
]

const ABOUT_PROSPECT_COMPANY = "About Prospect Company"
const THE_CHALLENGE = "The Challenge/Problem Statement"
const THE_SOLUTION = "The Solution"
const BENEFITS_AND_RESULTS = "Benefits and Results"

const BACKEND_ADDRESS = process.env.REACT_APP_BACKEND
const VOCODE_BACKEND = process.env.REACT_APP_VOCODE_BACKEND

var OVERALL_EVALUATION = {
  1: {
    //category: "On target!🎯",
    category: "Bullseye🎯",
    //comment: "Kudos, you are highly likely to convert such call attempts"
    comment: "Perfect! Calls like these will surely get you meetings booked"
  },
  2: {
    category: "Almost there🤏🏽",
    // comment: "With such call attempts, you will win some and lose some"
    comment: "You win some and lose some. A little bit of course correction and you will get there"
  },
  3: {
    category: "Big miss🫙",
    // comment: "Unless the prospect is generous, you’ve got no chance with such attempts"
    comment: "Unless the prospect is generous, you’ve got no chance! Keep trying and you will get better 😊"
  },
  4: {
    //category: "Crickets…🦗",
    category: "Pin drop Silence🤐",
    // comment: "Next time on a call, say something or the prospect is giving up on you"
    comment: "That was brief. Try to talk more- a bad attempt is still better than no attempt 😊"
  }
}

export {COLD_CALL, DISCOVERY}
export {FOLLOW_TALKING_POINTS, HANDLE_A_SITUATION, APPLY_A_TACTIC, SELL_TO_A_PERSONA}
export {PLAY_STAGE_HELPER_CONTENT, OVERALL_EVALUATION}
export {DEFAULT_TRACKS, STOPPING_CRITERIAS}
export {ABOUT_PROSPECT_COMPANY, THE_CHALLENGE, THE_SOLUTION, BENEFITS_AND_RESULTS}
export {BACKEND_ADDRESS, VOCODE_BACKEND}
