import { useSelector } from 'react-redux'

const WithUser = ({admin, manager, user, children}) => {
  const role = useSelector((state) => state.role.value)
  if(role === 'Admin'){
    return admin? <>{children}</>: <></>
  }
  if(role === 'Manager'){
    return manager? <>{children}</>: <></>
  }
  if(role === 'User'){
    return user? <>{children}</>: <></>
  }
  return <></>
};

export default WithUser
