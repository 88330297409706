import React, {useState, useEffect} from 'react';
// import { makeStyles } from '@mui/styles';
import useClasses from './utils/useClasses'
import { Router, Route, Switch, useHistory } from "react-router-dom";

import WithUser from './roleBasedAccess.js'
import ContentGrid from "./ContentGrid"
import Onboarding from "./Onboarding"

const drawerWidth = 80;
//const navBarWidth = 64

const useStyles = theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 8,
    //padding: theme.spacing(1),
    marginLeft: drawerWidth,
    marginTop:36,
    maxHeight: "100%",
    width: "100%",
    overflowY: "auto"
  }
});

export default function MainContent(props) {
  const next_path = window.location.pathname
  const classes = useClasses(useStyles);
  const [onboarded, setOnboarded] = useState(props.boarded)
  //as useState initial value is used only on 1st render-
  //https://stackoverflow.com/a/59308313/9160820
  useEffect(() => { setOnboarded(props.boarded)}, [props.boarded] )

  let history = useHistory()
  //console.log(props.boarded)

  return (
      <main className={classes.content}>
        <Router history={history}>
          <Switch>
            <Route path="/team" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="team" />
              </WithUser>
            } />

            <Route path="/companies/add_new" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="companies" {...props} subMode="add_company"/>
              </WithUser>
            } />

            <Route path="/companies/:c_id" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="companies" {...props} subMode="company_content"/>
              </WithUser>
            } />

            <Route path="/companies" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="companies" subMode="all_companies" {...props} />
                {onboarded?
                  <></>:
                  <Onboarding setOnboarded={setOnboarded}/>
                }
              </WithUser>
            } />

            {/*
            <Route path="/dashboard" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="dashboard" />
              </WithUser>
            } />
            */ }

            <Route path="/profile" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="profile" />
              </WithUser>
            } />

            <Route path="/settings" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="settings" />
              </WithUser>
            } />

            <Route exact path="/tracks/:t_id/plays/add_new" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="tracks" subMode="add_play" {...props}/>
              </WithUser>
            } />

            <Route path="/tracks/:t_id/plays/:m_id" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="tracks" subMode="play_content" {...props}/>
              </WithUser>
            } />

            <Route path="/all_tracks/:t_id/plays/:m_id" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="tracks" subMode="play_content_user" {...props}/>
              </WithUser>
            } />

            <Route exact path="/tracks/add_new" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="tracks" subMode="add_track" {...props}/>
              </WithUser>
            } />

            <Route path="/tracks/:t_id" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="tracks" subMode="track_content" {...props}/>
              </WithUser>
            } />

            <Route path="/all_tracks/:t_id" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="tracks" subMode="track_content_user" {...props}/>
              </WithUser>
            } />

            <Route path="/tracks" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="tracks" subMode="all_tracks" {...props}/>
              </WithUser>
            } />

            <Route path="/all_tracks" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="tracks" subMode="all_tracks_user" {...props}/>
              </WithUser>
            } />

            <Route exact path="/attempt/:a_id" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="tracks" subMode="play_content_user" {...props}/>
              </WithUser>
            } />

            <Route path="/" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="tracks" subMode="all_tracks_user" next_path={next_path} {...props}/>
              </WithUser>
            } />

          {//Adding no match routes. Please don't add any routes below this
          }
          <Route path="/*" render={(props) =>
              <WithUser user manager>
                <ContentGrid mode="tracks" subMode="all_tracks" {...props}/>
              </WithUser>
          }/>

        </Switch>
        </Router>
      </main>
  );
}
