import React, {useEffect, useState} from "react"
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {Grid, Typography} from '@mui/material';
import Paper from '@mui/material/Paper';
//import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ToastComponent from "./../utils/ToastComponent";
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';

import IconButton from '@mui/material/IconButton';
import HelpIcon from '@mui/icons-material/Help';
import TuneIcon from '@mui/icons-material/Tune';
import Button from '@mui/material/Button';
import axios from "axios"
import { BACKEND_ADDRESS } from "./../utils/getEndpoint"
import Loading from "./../utils/Loading"
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';

import {ABOUT_PROSPECT_COMPANY, THE_SOLUTION, THE_CHALLENGE, BENEFITS_AND_RESULTS, BUYER_COMPANY_FIELDS} from "./../utils/constants"

var allBuyerCompanyFields = [
  ABOUT_PROSPECT_COMPANY,
  THE_CHALLENGE,
  THE_SOLUTION,
  BENEFITS_AND_RESULTS
]

const companyHeadingStyle = {
  fontWeight: "medium", overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '1', WebkitBoxOrient: 'vertical'
} //all this is added to constraint heading to one line- https://stackoverflow.com/a/71324014/9160820
const companyDescriptionStyle = {
  fontWeight: "medium", overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '3', WebkitBoxOrient: 'vertical'
} //all this is added to constraint heading to one line- https://stackoverflow.com/a/71324014/9160820

function CompanyCard(props){
  const [delPopup, setDelPopup] = useState(false)

  const onDelete = () => {
    axios.delete(BACKEND_ADDRESS + '/companies/get_company/', {'params': {'id': props.data["_id"]["$oid"]}})
    .then((response) => {
      ToastComponent("info", "Buyer Company deleted!", "#604ca5")
      //props.history.push("/companies")
      props.history.go(0)
    }).catch(error => {
      console.error(error);
      ToastComponent("error", "Couldn't delete buyer company")
    })
  }

  return (
    <>
      <Paper style={{height: "160px", width: "90%", borderRadius: "8px", border: "1px", padding: "24px", position: "relative"}}
      elevation={4} >
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Typography display="inline" variant="h6" sx={companyHeadingStyle}><b>{props.data["company"]}</b></Typography>
          </Grid>
          <Grid item>
            {props.data["is_template"]?
              <IconButton onClick={() => props.selectCompany(props.data["_id"]["$oid"])}>
                <VisibilityIcon />
              </IconButton>
              :
              <>
                <IconButton onClick={() => props.selectCompany(props.data["_id"]["$oid"])}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => setDelPopup(true)}>
                  <DeleteIcon />
                </IconButton>
              </>
            }
          </Grid>
        </Grid>

        <Typography style={{paddingTop: "8px"}} sx={companyDescriptionStyle}>{props.data["description"]}</Typography>
        {props.data.is_template?
          <div style={{position: "absolute", bottom: "10px"}}>
            <Tooltip title="Sample buyer companies are readymade buyer companies to get you started quickly!">
              <Chip label="Sample Buyer Company" style={{color: "#604ca5"}}/>
              &nbsp;
            </Tooltip>
          </div>
          :<></>
        }
      </Paper>
      <Dialog open={delPopup} onClose={() => setDelPopup(false)}>
        <DialogTitle>
          Are you sure you want to delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Your team members might have already attempted plays using this buyer company. Their progress will be lost if you delete this buyer company.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDelPopup(false)} autoFocus>Cancel</Button>
          <Button onClick={onDelete} style={{color: "red"}}>Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

function AddCompanyCard(props){
  return (
    <Paper style={{height: "160", width: "90%", borderRadius: "8px", border: "1px", borderStyle: "dashed", padding: "24px", backgroundColor: "#fbfafe"}}
    elevation={4} sx={{':hover': {boxShadow: 10, cursor: "pointer"}}} onClick={props.onClick}>
      <Typography display="inline" variant="h6"><b>Add new Buyer Company</b></Typography>
      <Typography style={{padding: "16px 0px 8px "}}>Add a new buyer company describing buying stories of one of your prominent customers.</Typography>
      <center><ControlPointIcon color="primary" fontSize="large"/></center>
    </Paper>
  )
}

function CompanyContent(props){
  const [currentCompanyData, setCurrentCompanyData] = useState(props.content)
  const [sellerCompanyName, setSellerCompanyName] = useState("")
  const [sellerCompanyWebsite, setSellerCompanyWebsite] = useState("")
  const [showLoading, setShowLoading] = useState(false)

  const account = useSelector((state) => state.account.value);
  const is_template = props.content.is_template
  const setProspectDesignation = (e) => {var temp = currentCompanyData; temp["prospect_designation"] = e.target.value; setCurrentCompanyData(temp)}
  const setContent = (e, val) => {var temp = currentCompanyData; temp["content"][val] = e.target.value; setCurrentCompanyData(temp)}
  const setCompany = (e) => {var temp = currentCompanyData; temp["company"] = e.target.value; setCurrentCompanyData(temp)}
  const setRelationship = (e) => {var temp = currentCompanyData; temp["description"] = e.target.value; setCurrentCompanyData(temp)}

  useEffect(() => {
    if(!is_template){
      axios.get(BACKEND_ADDRESS + '/account/', {params: {account_id: account}})
      .then((response) => {
        var tt = response.data
        setSellerCompanyName(tt["company_name"])
        setSellerCompanyWebsite(tt["company_website"])
      }).catch(error => console.error(error));
    }
  }, [])

  const onSave = () => {
    const checkBlankFields = () => {
      if(currentCompanyData['company'] === "" || currentCompanyData['description'] === "" || currentCompanyData['prospect_designation'] === ""){
        return 1
      }else if(currentCompanyData['content'][ABOUT_PROSPECT_COMPANY] === "" ||
      currentCompanyData['content'][THE_CHALLENGE] === "" ||
      currentCompanyData['content'][THE_SOLUTION] === "" ||
      currentCompanyData['content'][BENEFITS_AND_RESULTS] === ""){
        return 2
      }else{
        return 3
      }
    }

    const checkAllAdvancedBlankFields = () => {
      if(currentCompanyData['content'][ABOUT_PROSPECT_COMPANY] === "" &&
      currentCompanyData['content'][THE_CHALLENGE] === "" &&
      currentCompanyData['content'][THE_SOLUTION] === "" &&
      currentCompanyData['content'][BENEFITS_AND_RESULTS] === "") return true
      else return false
    }

    const checkShortFields = (onlyBasicFields) => {
      if(currentCompanyData['company'].length < 2){
        ToastComponent("error", "Can't save as 'Prospect Company Name' is too short")
        return false
      }

      if(currentCompanyData['description'].length < 5){
        ToastComponent("error", "Can't save as 'Prospect Relationship' is too short")
        return false
      }

      if(currentCompanyData['prospect_designation'].length < 3){
        ToastComponent("error", "Can't save as 'Prospect Designation' is too short")
        return false
      }

      if(!onlyBasicFields){
        if(currentCompanyData['content'][ABOUT_PROSPECT_COMPANY].length < 20){
          ToastComponent("error", "Can't save as " + ABOUT_PROSPECT_COMPANY + " is too short")
          return false
        }

        if(currentCompanyData['content'][THE_CHALLENGE].length < 20){
          ToastComponent("error", "Can't save as " + THE_CHALLENGE + " is too short")
          return false
        }

        if(currentCompanyData['content'][THE_SOLUTION].length < 20){
          ToastComponent("error", "Can't save as " + THE_SOLUTION + " is too short")
          return false
        }

        if(currentCompanyData['content'][BENEFITS_AND_RESULTS].length < 20){
          ToastComponent("error", "Can't save as " + BENEFITS_AND_RESULTS + " is too short")
          return false
        }
      }

      return true
    }

    var v = checkBlankFields()
    var onlyBasicFields;
    if (v === 1){
      ToastComponent("error", "Can't save buyer company as some fields are not filled!")
      return
    }else if (v === 2){
      if(!checkAllAdvancedBlankFields()){
        ToastComponent("error", "You either need to fill all Advanced Parameters or none of them. Try Again!")
        return
      }else{
        onlyBasicFields = true
      }
    }else{
      onlyBasicFields = false
    }

    if(!checkShortFields(onlyBasicFields))
      return

    if(props.newCompany){
      //POST
      axios.post(BACKEND_ADDRESS + '/companies/get_company/', currentCompanyData)
      .then((response) => {
        ToastComponent("info", "Buyer Company created!", "#604ca5")
        props.history.push("/companies")
      }).catch(error => {
        console.error(error);
        ToastComponent("error", "Couldn't create buyer company")
      })
    }else{
      //PUT
      var temp = currentCompanyData;
      temp["id"] = currentCompanyData["_id"]["$oid"]

      setShowLoading(true)
      axios.put(BACKEND_ADDRESS + '/companies/get_company/', temp)
      .then((response) => {
        setShowLoading(false)
        ToastComponent("info", "Buyer Company updated!", "#604ca5")
      }).catch(error => {
        console.error(error);
        setShowLoading(false)
        ToastComponent("error", "Couldn't update buyer company")
      })
    }
  }

  if('content' in props.content){
    const topics = Object.keys(props.content["content"])
    var filledTopics = false;
    for (var i=0;i<topics.length;i+=1){
      if(props.content["content"][topics[i]] !== "") filledTopics= true
    }

    return (
      <Grid container direction="row" spacing={1}>
        <Grid item xs={9}>
          <Paper style={{borderRadius: "8px", border: "1px", padding: "24px"}} elevation={0}>
            <Accordion disableGutters defaultExpanded elevation={0} style={{'&::before': {display: 'none'}}}>
              <AccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
              style={{flexDirection: 'row-reverse', padding: 0,
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                  transform: 'rotate(90deg)',
              }}}>
                <Typography color="primary" variant="h6">&nbsp;&nbsp;Basic Parameters</Typography>
              </AccordionSummary>
              <AccordionDetails style={{padding: 0}}>
                <Grid container item direction="row" spacing={2}>
                {/*<Grid item xs={4}>
                  <TextField label="Prospect Name" defaultValue={props.content["prospect_name"]} variant="outlined"
                  required sx={{width: "100%", marginTop: "12px"}} helperText="Name of the prospect who made the purchase" FormHelperTextProps={{sx: {color: "#604ca5"}}}/>
                </Grid>
                */}
                {is_template || (sellerCompanyName !== "" && sellerCompanyWebsite !== "")?
                  <>
                    <Tooltip title={is_template?"":"You can edit this in Settings section"}>
                      <Grid item xs={6}>
                        <TextField disabled label="Seller Company Name" defaultValue={is_template?props.content["seller_company"]:sellerCompanyName} variant="outlined"
                        required sx={{width: "100%", marginTop: "12px"}} helperText="Name of the seller company" FormHelperTextProps={{sx: {color: "#604ca5"}}}/>
                      </Grid>
                    </Tooltip>
                    <Tooltip title={is_template?"":"You can edit this in Settings section"}>
                      <Grid item xs={6}>
                        <TextField disabled label="Seller Website" defaultValue={is_template?props.content["seller_website"]:sellerCompanyWebsite} variant="outlined"
                        required sx={{width: "100%", marginTop: "12px"}} helperText="Seller Website URL" FormHelperTextProps={{sx: {color: "#604ca5"}}}/>
                      </Grid>
                    </Tooltip>
                  </>:<></>
                }
                {props.newCompany?
                  <Grid item xs={6}>
                    <TextField InputProps={{readOnly: is_template}} label="Prospect Company Name" defaultValue="" variant="outlined" onChange={(e) => setCompany(e)}
                    required sx={{width: "100%", marginTop: "12px"}} helperText="Name of the prospect company" FormHelperTextProps={{sx: {color: "#604ca5"}}}/>
                  </Grid>
                  :
                  <></>
                }
                <Grid item xs={props.newCompany?6:12}>
                  <TextField InputProps={{readOnly: is_template}} label="Prospect Designation" defaultValue={props.content["prospect_designation"]} variant="outlined" onChange={(e) => setProspectDesignation(e)}
                  required sx={{width: "100%", marginTop: "12px"}} helperText="Prospect's designation in their company" FormHelperTextProps={{sx: {color: "#604ca5"}}}/>
                </Grid>
                <Grid item xs={12}>
                  <TextField InputProps={{readOnly: is_template}} label="Use Case Description" defaultValue={props.content["description"]} variant="outlined" onChange={(e) => setRelationship(e)} multiline
                  required sx={{width: "100%", marginTop: "12px"}} helperText="One line description of how the prospect company gains/might gain value from your product" FormHelperTextProps={{sx: {color: "#604ca5"}}}/>
                </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion disableGutters defaultExpanded={filledTopics} elevation={0} style={{'&::before': {display: 'none'}}}>
              <AccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
              style={{flexDirection: 'row-reverse', padding: 0,
                '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                  transform: 'rotate(90deg)',
              }}}>
                <Typography color="primary" variant="h6">&nbsp;&nbsp;Advanced Parameters</Typography>
              </AccordionSummary>
              <AccordionDetails style={{padding: 0}}>
                <Grid container item direction="row" spacing={2}>
                  {topics.map((val, ind) => {
                    return (
                      (allBuyerCompanyFields.includes(val))?
                      <Grid item key={ind} xs={12}>
                        <TextField InputProps={{readOnly: is_template}} label={val} defaultValue={props.content["content"][val]} variant="outlined" onChange={(e) => setContent(e, val)}
                        sx={{width: "100%", marginTop: "12px"}} multiline FormHelperTextProps={{sx: {color: "#604ca5"}}}
                        helperText={"Describe in upto " + ((val === ABOUT_PROSPECT_COMPANY)?"50":"200") + " words"}
                        minRows={(val === ABOUT_PROSPECT_COMPANY)?1:3} />
                      </Grid>
                      :<div key={ind}></div>
                    )
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>

            {is_template?
              <></>:
              <Paper square style={{position: "sticky", bottom: "0px", zIndex: 5, width: "100%", marginLeft: "2px"}} elevation={0}>
                <center>
                  <Button onClick={onSave} autoFocus variant="contained"
                  sx={{textTransform: 'none', marginBottom: "6px"}}>
                    Save Company
                  </Button> 
                </center>
              </Paper>
            }
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showLoading}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </Paper>
        </Grid>
      {is_template?
      <></>:
      <Grid item xs={3}>
        <Paper style={{borderRadius: "8px", border: "1px", padding: "24px", backgroundColor: "#ffffad"}} elevation={1}>
          <Typography variant="h6" color="primary">
            <b>📌Creating a Buyer Company</b><br />
          </Typography>
          <Typography>
            <br /><i>Want ideas on how to fill this section? Look <a href="/companies/654b7c0997e3ad87c3fce647" target="_blank">here</a></i><br />

            <br />👉 If you want to quickly try a roleplay, fill the <b>Basic Parameters only</b>.<br />
              <b><i>Estimated Time⌛: 2 mins</i></b><br />

            <br />👉 If you want to do roleplays that closely mirror your actual buyers' language, ALL <b>Advanced Parameters</b> must be filled. (You may use your Customer Case Studies & Testimonials to fill this section quicker)<br />
              <b><i>Estimated Time⌛: 10 mins</i></b><br />
          </Typography>
        </Paper>
      </Grid>
      }
      </Grid>
    )
  }else{
    return <Typography>Couldn't find buyer company</Typography>
  }
}

export default function Companies(props) {
  var initial_val = -1;

  if(props.subMode === "add_new") initial_val = 0
  else if(props.subMode === "company_content"){
    initial_val = props.match.params.c_id
  }

  const activePage = props.subMode
  const [activeCompany, setActiveCompany] = useState({})
  const [buyerCompanies, setBuyerCompanies] = useState([])
  const [help, setHelp] = useState(false)
  let history = useHistory();
  const email = useSelector((state) => state.email.value);
  const account = useSelector((state) => state.account.value);

  useEffect(() => {
    props.callback("bg", "#fbfafe")
    window.scrollTo(0,0) //scrolling to top of new screen

    if(activePage === "all_companies"){
      //get role and onboarding status and store it in redux
      axios.get(BACKEND_ADDRESS + '/companies/get_company_templates/', {})
      .then((response) => {
        var allCompanies = response.data
        axios.get(BACKEND_ADDRESS + '/companies/get_all_companies/', {'params': {'account_id': account }})
        .then((response) => {
          allCompanies = allCompanies.concat(response.data);
          setBuyerCompanies(allCompanies)
        })
      }).catch(error => console.error(error));
    } else if(activePage === "company_content"){
      axios.get(BACKEND_ADDRESS + '/companies/get_company/', {params: {email: email, id: initial_val}})
      .then((response) => {
        setActiveCompany(response.data[0])
      }).catch(error => console.error(error));
    }
  }, []);

  useEffect(() => {
    if(activePage === "all_companies"){
      props.callback("heading", <>Buyer Companies<IconButton sx={{color: "#ffffff"}} onClick={() => setHelp(true)}>❓</IconButton></>)
      props.callback("description", "Setup your actual/typical buyer companies, so that you can roleplay with AI prospects from those companies")
    } else if(activePage === "company_content"){
      if(activeCompany !== null){
        var heading
        if(activeCompany["is_template"]) heading = <>{activeCompany["company"]}&nbsp;<Chip label="Sample Buyer Company" style={{color: "#604ca5"}} /></>
        else heading = activeCompany["company"]

        props.callback("heading", heading)
        props.callback("description", activeCompany["description"])
      }
    } else if(activePage === "add_company"){
      props.callback("heading", "Define a Buyer Company")
      props.callback("description", "Setup your actual/typical buyer companies, so that you can roleplay with AI prospects from those companies")
    }
  }, [activeCompany])

  const emptyCompany = {
    company: '',
    description: '',
    //prospect_name: '',
    account_id: account,
    prospect_designation: '',
    is_active: true,
    is_draft: false,
    is_template: false,
    content: {
      [ABOUT_PROSPECT_COMPANY]: "",
      [THE_CHALLENGE]: "",
      [THE_SOLUTION]: "",
      [BENEFITS_AND_RESULTS]: ""
    },
    content_extended: false
  }

  const selectCompany = (ind) => {
    history.push('/companies/' + String(ind));
  }

  const addCompany = () => {
    history.push('/companies/add_new');
  }

  return (
    <>
    {activePage === "all_companies"?
      <>
      <Grid container direction="row" spacing={4}>
        <Grid item xs={12} md={6} lg={4} xl={3}>
          <AddCompanyCard onClick={addCompany}/>
        </Grid>
        {Object.keys(buyerCompanies).map((val, ind) => {
          return (
            buyerCompanies[val].is_template?
            <></>
            :
            <Grid item key={ind} xs={12} md={6} lg={4} xl={3}>
              <CompanyCard data={buyerCompanies[val]} ind={ind} selectCompany={selectCompany} history={history}/>
            </Grid>
          )
        })}
        <Grid item xs={12}>
          <Typography variant="h6">
            <b>Sample Buyer Companies</b>
          </Typography>
        </Grid>
        {Object.keys(buyerCompanies).map((val, ind) => {
          return (
            !(buyerCompanies[val].is_template)?
            <></>
            :
            <Grid item key={ind} xs={12} md={6} lg={4} xl={3}>
              <CompanyCard data={buyerCompanies[val]} ind={ind} selectCompany={selectCompany} history={history}/>
            </Grid>
          )
        })}
      </Grid>
      <Dialog open={help} onClose={() => setHelp(false)}>
        <DialogTitle>
          What are Buyer Companies?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            A Buyer Company is a typical prospective customer of your product. Setting up a buyer company in Aspiro helps our AI
            build a realistic understanding of your actual buyers.<br /><br />We have already created <b><i>Sample Buyer Companies</i></b> for
            you to quickly get started with roleplaying. However, we recommend you to take a few minutes to create buyer companies, so
            as to make your roleplays more aligned to your actual call experience.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setHelp(false)} style={{textTransform: "none"}}>Got It!</Button>
        </DialogActions>
      </Dialog>
      </>
    :
    activePage === "company_content"?
      Object.keys(activeCompany).length > 0 ?
        <CompanyContent content={activeCompany} id={initial_val}/>
        :
        <></>
    :
    activePage === "add_company"?
      <CompanyContent content={emptyCompany} newCompany={true} history={history}/>
    :
    <></>
    }
    </>
  )
}
