import {FOLLOW_TALKING_POINTS, SELL_TO_A_PERSONA, APPLY_A_TACTIC, HANDLE_A_SITUATION} from "./constants"

// https://www.freecodecamp.org/news/how-to-validate-urls-in-javascript/
const isValidUrl = (str) => {
  const pattern = new RegExp(
    '^([a-zA-Z]+:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  )
  return pattern.test(str);
}

// https://stackoverflow.com/a/37438675/9160820
// generate new object id for saving in DB
const objectId = (m = Math, d = Date, h = 16, s = s => m.floor(s).toString(h)) =>
  s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h))

// format time into HH:MM:SS format
const formatTime = (seconds) => {
   const hours = Math.floor(seconds / 3600);
   const minutes = Math.floor((seconds % 3600) / 60);
   const remainingSeconds = seconds % 60;

   const formattedHours = String(hours).padStart(2, '0');
   const formattedMinutes = String(minutes).padStart(2, '0');
   const formattedSeconds = String(remainingSeconds).padStart(2, '0');

   if(hours === 0 && minutes === 0) return `0:${formattedSeconds}`
   if(hours === 0) return `${formattedMinutes}:${formattedSeconds}`

   return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

const getRoleplayVerdict = (evaluation) => {
  try{
    const keys = Object.keys(evaluation["evaluation"])
    var satisfactory = 0, unsatisfactory = 0, rating;

    for(var i=0;i<keys.length;i+=1){
      if(evaluation["evaluation"][keys[i]]['result'] === 'SATISFACTORY') satisfactory += 1
      else unsatisfactory += 1
    }

    if(satisfactory + unsatisfactory < 5){
      if(Object.keys(evaluation.evaluation).length === 0) rating = 4
      else if(unsatisfactory === 0) rating = 1
      else if (satisfactory > unsatisfactory) rating = 2
      else rating = 3
    }else{
      const winPercentage = satisfactory/(satisfactory + unsatisfactory);

      if(Object.keys(evaluation.evaluation).length === 0) rating = 4
      else if(winPercentage >= 0.8) rating = 1
      else if (winPercentage >= 0.5) rating = 2
      else rating = 3
    }
  }catch(err) {return 3}

  return rating
}
export {isValidUrl, objectId, formatTime, getRoleplayVerdict}
