import React, {useState} from "react"
import {Grid,TextField ,Button ,InputAdornment, Paper} from '@mui/material';
//import {makeStyles} from '@mui/styles';
import {MailOutline} from '@mui/icons-material'
import { Typography } from '@mui/material';
import fire from "./../utils/base";
import ToastComponent from "./../utils/ToastComponent";

import { Link } from '@mui/material';

export default function ForgotPassword (){
    const [email, setEmail] = useState();
    const [mailsent, setMailSent] = useState(false);
    //const classes = useStyles();
    async function passwordReset(){
      if(mailsent===false){
        try {
         await fire.auth().sendPasswordResetEmail(email);
         ToastComponent("info","Please check your inbox, to reset your password")
         setMailSent(true);
        }catch(e) {
         console.log(e)
         ToastComponent("error", e.message)
         return
        }
      }
    }

    return(
      <Grid container alignItems="center" justifyContent="center" style={{background: 'linear-gradient(0deg, rgba(162,157,236,1) 0%, rgba(220,218,254,1) 100%)', position :'absolute', top:'0', bottom:'0', left: '-2', right: '-2'}}>
        <Grid item xs={11} sm={8} md={6} lg={4} xxl={3}>
          <Paper sx={{ borderRadius: "20px", padding: "24px", background: "#fbfafe", height: "500px", maxWidth: "100%"}}>
            <Grid container direction="column" xs={12} align="center" justifyContent="center" style={{height: "100%"}}>
              <Grid item>
                <a href="https://letsaspiro.com">
                  <center><img src="../landing_2.png" height="40" alt="sales roleplay using AI prospects"/></center><br />
                </a>
              </Grid>
              <Grid item>
                <Typography>
                    Please enter your email ID to receive a password-reset email
                </Typography>
                <br />
                <form>
                  <Grid item align="center">
                    <TextField required variant = "outlined"
                      label = " Work Email" onChange = {e => setEmail(e.target.value)}
                      InputProps={{startAdornment: (
                        <InputAdornment position="start">
                          <MailOutline />
                        </InputAdornment>
                      ),
                      }}
                      sx={{ m: 1, width: '80%'}}
                    />
                  </Grid>
                </form>
              </Grid>
              <Grid item>
                <Button autoFocus color="primary" variant = "contained" align="center" style={{textTransform: 'none'}}
                onClick = {passwordReset}>
                  Send Mail
                </Button>
              </Grid>
              <Grid item>
                <br />
                <Typography> or <Link href="/signin">Sign In</Link> instead
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );

}
