import firebase from 'firebase'
import "firebase/auth";
import "firebase/analytics"
//require('dotenv').config();

const prodConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain:process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_API_ID,
};


const fire = firebase.initializeApp(prodConfig);
const getanalytics = fire.analytics();

export default fire;
export {getanalytics}
