import {useState} from "react"
import {useHistory} from "react-router-dom";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Button from '@mui/material/Button';

export default function MeetingCTA(props) {
  const [display, setDisplay] = useState(props.show)
  let history = useHistory();

  const onClose = () => {
    setDisplay(false)
    props.callback()
    if(!(props.redirect === false)) history.push('/all_tracks')
  }

  return (
    <Dialog open={display} onClose={onClose}>
      <DialogTitle>
        Talk to Us
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          You stumbled across a PRO feature. Please email us at hello@letsaspiro.com or <a href="https://calendly.com/puneetlamba/aspiro15min" target="_blank">book a demo</a> with us now to unlock.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} style={{textTransform: 'none'}} autoFocus>Okay</Button>
      </DialogActions>
    </Dialog>
  )
}
